<template>
  <div class="container position-relative">
    <div class="loading">
      <div class="spinner-border loading-spinner mb-2" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="loading-text">Loading</div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from "vue";
import { login } from "@/services/auth-service";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { swalAlert } from "@/utilities/sweet-alert.js";

export default {
  name: "Login",
  setup() {
    const { swalAlt } = swalAlert();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      count: 0,
    });
    var swalOption = {
      buttonsStyling: true,
      showCancelButton: true,
      showConfirmButton: false,
      html: "",
      cancelButtonText: "ปิด",
      cancelButtonColor: "#fff",
      background: "#ff7070",
    };

    onMounted(async () => {
      const token = route.query.t;
      const response = await login(token);
      if (response.status) {
        localStorage.setItem("isLoggedIn", "true");
        store.commit(
          "priceModule/SET_BALANCE_DATA",
          response.data.balance / 100
        );
        store.commit("defaultModule/SET_IsLoggedIn", true);
        await router.push({ name: "Streamer" });
      } else {
        swalOption.html = `<span style='color:#fff'>${response.message}<span>`;
        swalAlt.fire(swalOption);
      }
    });
    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss" scoped></style>
