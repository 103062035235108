export async function request_demo(url, data) {
  if (/profile$/.test(url)) {
    return api_profile();
  } else if (/game-price\/0$/.test(url)) {
    return api_gameprice();
  } else if (/ticket-gameprice\/0$/.test(url)) {
    return api_ticket_gameprice();
  } else if (/sendticket$/.test(url)) {
    return api_sendTicket(data);
  }
}
export function isDemoMode() {
  if (/\/game\/0$/.test(window.location)) {
    return true;
  }
  return false;
}

function api_profile() {
  const json = JSON.parse(
    '{ "status": true, "message": null, "data": { "username": "demo01", "balance": 1000000, "status": 200 } }'
  );
  console.log(json);
  return json;
}

function api_gameprice() {
  const json = JSON.parse(
    `{
      "status":true,
      "message":null,
      "data":{
        "id":0,
        "league_id":6,
        "league_name":"\u0e17\u0e14\u0e2a\u0e2d\u0e1a league",
        "http_stream_url":"https://stream.r2b.tv/video/muaysod_480p/playlist.m3u8",
        "sport_id":1,
        "home_name":"demo1",
        "away_name":"demo2",
        "position":1,
        "poster":null,
        "home_price":"10/8",
        "away_price":"8/10",
        "home_price_limit":100000,
        "away_price_limit":100000,
        "is_price_open":true,
        "is_pause":false,
        "status":"live",
        "result":"wait",
        "game_id":237,
        "round_id":0,
        "streamer_id":2,
        "bet_min":null,
        "ticket_delay_time":10,
        "pin_text":null,
        "name":"stream2",
        "streamer_image":null,
        "round_text":"ชกยก 1",
        "total_ticket":0,
        "total_home_amount":0,
        "total_home_ticket":0,
        "total_away_amount":0,
        "total_away_ticket":0,
        "if_home_win":0,
        "if_away_win":0
      }
    }`
  );
  console.log(json);
  return json;
}

export function api_ticket_gameprice() {
  const json = JSON.parse(
    `{
      "status":true,
      "message":null,
      "data":[]
    }`
  );
  console.log(json);
  return json;
}

export function api_sendTicket(data) {
  const balance = localStorage.getItem("bl");
  const home_price = localStorage.getItem("hpr");
  const away_price = localStorage.getItem("apr");
  console.log(balance);
  const json = JSON.parse(
    `{
      "status":true,
      "message":"ส่งบิลเรียบร้อย",
      "data":{
        "id":1,
        "balance":${+balance * 100 - data.amount},
        "home_price":"${home_price}",
        "away_price":"${away_price}",
        "round_id":0,
        "round_text":"ชกยก 3",
        "bet_team":"${data.bet_team}",
        "status":200,
        "amount":${data.amount}
      }
    }`
  );
  console.log(json);
  return json;
}

function demoWss_updateGamePrice_randData(gamePriceData, init = false) {
  let priceData = [
    ["10/9", "9/10"],
    ["4/5", "10/10"],
    ["4/5", "10/10"],
    ["8/11", "10/10"],
    ["2/3", "10/10"],
    ["3/5", "10/9"],
    ["4/7", "10/9"],
    ["2/5", "3/2"],
    ["1/2", "5/4"],
    ["1/3", "5/3"],
    ["1/4", "2/1"],
    ["1/5", "2/1"],
    ["1/6", "3/1"],
    ["1/7", "3/1"],
    ["1/8", "4/1"],
    ["1/9", "4/1"],
    ["1/10", "4/1"],
    ["1/15", "5/1"],
    ["1/20", "10/1"],
    ["1/25", "10/1"],
    ["1/30", "15/1"],
    ["1/40", "20/1"],
    ["1/45", "20/1"],
    ["1/50", "25/1"],
    ["1/60", "30/1"],
    ["1/70", "30/1"],
    ["1/80", "40/1"],
    ["1/90", "40/1"],
    ["1/100", "50/1"],
  ];
  // console.log("TEST");
  if (Math.random() > 0.5 || init === true) {
    let i = Math.floor(Math.random() * priceData.length);
    if (Math.random() > 0.5) {
      gamePriceData["msg"]["home_price"] = priceData[i][1];
      gamePriceData["msg"]["away_price"] = priceData[i][0];
    } else {
      gamePriceData["msg"]["home_price"] = priceData[i][0];
      gamePriceData["msg"]["away_price"] = priceData[i][1];
    }

    gamePriceData["msg"]["total_home_amount"] +=
      Math.floor(Math.random() * 100) * 100;
    gamePriceData["msg"]["total_away_amount"] +=
      Math.floor(Math.random() * 100) * 100;
    return true;
  }
  return false;
}
export function demoWss_updateGamePrice(updateGamePriceData) {
  if (updateGamePriceData["init"] !== true) {
    updateGamePriceData["lastChangeTm"] = Date.now();
    updateGamePriceData["data"] = {
      cmd: "updateGamePrice",
      msg: {
        id: 0,
        home_price: "10/9",
        away_price: "3/5",
        bet_min: null,
        home_price_limit: 200000,
        away_price_limit: 200000,
        is_price_open: true,
        status: "live",
        result_at: null,
        result: "wait",
        created_at: 1656295954,
        game_id: 237,
        streamer_id: 2,
        round_id: 6,
        pin_text: null,
        ticket_delay_time: 0,
        is_pause: false,
        last_change_price: "2022-09-27 09:57:51",
        round_text: "ยกที่ 3",
        last_ticket_commit: 1730,
        total_home_amount: 1000,
        total_away_amount: 1000,
      },
    };
    demoWss_updateGamePrice_randData(updateGamePriceData["data"], true);
    updateGamePriceData["init"] = true;
    return JSON.parse(JSON.stringify(updateGamePriceData["data"]));
  }
  if (
    Date.now() - updateGamePriceData["lastChangeTm"] >
    Math.floor(Math.random() * (10000 - 5000 + 1) + 5000)
  ) {
    updateGamePriceData["lastChangeTm"] = Date.now();
    if (demoWss_updateGamePrice_randData(updateGamePriceData["data"])) {
      return JSON.parse(JSON.stringify(updateGamePriceData["data"]));
    } else {
      return null;
    }
  }
  return null;
}

function demoWss_ticket_randData(ticketData, gamePriceData, sendTicketData) {
  const teamArr = ["home", "away"];
  const nameArr = [
    "demo1****",
    "demo2****",
    "demo3****",
    "demo4****",
    "demo5****",
  ];
  if (sendTicketData !== null) {
    ticketData.msg.home_price = gamePriceData.home_price;
    ticketData.msg.away_price = gamePriceData.away_price;
    ticketData.msg.masked_username = "demo0****";
    ticketData.msg.bet_team = sendTicketData.bet_team;
    ticketData.msg.amount = sendTicketData.amount;
  } else {
    ticketData.msg.home_price = gamePriceData.home_price;
    ticketData.msg.away_price = gamePriceData.away_price;
    ticketData.msg.masked_username = nameArr[Math.floor(Math.random() * 5)];
    ticketData.msg.bet_team = teamArr[Math.floor(Math.random() * 2)];
    ticketData.msg.amount = 1000 + Math.floor(Math.random() * 100) * 1000;
  }
  return ticketData;
}

export function demoWss_newTicket(
  updateTicketData,
  gamePriceData,
  sendTicketData = null
) {
  if (updateTicketData["init"] !== true) {
    updateTicketData["lastChangeTm"] = Date.now();
    updateTicketData["data"] = {
      cmd: "newTicket",
      msg: {
        streamer_id: 2,
        game_price_id: 0,
        masked_username: "demo0******",
        bet_team: "away",
        home_price: gamePriceData.home_price,
        away_price: gamePriceData.away_price,
        amount: 10000,
      },
    };
    updateTicketData["init"] = true;
  }
  if (
    Date.now() - updateTicketData["lastChangeTm"] >
      Math.floor(Math.random() * (4000 - 1000 + 1) + 1000) ||
    sendTicketData !== null
  ) {
    updateTicketData["lastChangeTm"] = Date.now();
    demoWss_ticket_randData(
      updateTicketData["data"],
      gamePriceData,
      sendTicketData
    );
    return JSON.parse(JSON.stringify(updateTicketData["data"]));
  }
  return null;
  // (42)[
  //   ("cmd",
  //   {
  // cmd: "newTicket",
  // msg: {
  //   streamer_id: 2,
  //   game_price_id: 335,
  //   masked_username: "cust******",
  //   bet_team: "away",
  //   home_price: "1/35",
  //   away_price: "15/1",
  //   amount: 10000,
  // },
  //   })
  // ];
}
