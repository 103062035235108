<template>
  <router-view />
</template>

<script>
import { onMounted } from "@vue/runtime-core";
import NoSleep from "nosleep.js";
import { useMobileDetection } from "vue3-mobile-detection";

export default {
  setup() {
    const { isMobile } = useMobileDetection();
    onMounted(() => {
      calculateHeight();
      if (isMobile()) {
        var noSleep = new NoSleep();
        noSleep.enable();
      }
    });
    const calculateHeight = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);

      window.addEventListener("resize", () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
      });
    };
    return {};
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
