import { h, resolveComponent } from "vue";
import Game from "@/views/game/Game.vue";

const gameRoutes = [
  {
    path: "",
    name: "GameRoute",
    component: {
      render: () => h(resolveComponent("router-view")),
    },
    children: [
      {
        path: "/game/:id",
        name: "Game",
        component: Game,
        meta: { keepWSS: true, checkRefresh: true },
      },
    ],
  },
];

export default gameRoutes;
