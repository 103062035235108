<template>
  <div
    v-show="!isHiddenContent"
    class="row pt-2 bg-32 live-price-div position-relative"
    :class="
      gamePrice.is_price_open === false || gamePrice.is_pause === true
        ? 'disabled'
        : ''
    "
  >
    <div
      v-if="
        approveTime >= 0 &&
        gameObj.auto_approve_time > 0 &&
        gameObj.auto_approve_time !== null &&
        gamePrice.is_price_open === true
      "
      class="close-time py-2"
    >
      <div><i class="fas fa-stopwatch"></i> {{ approveTime }}</div>
    </div>
    <div
      v-if="gamePrice.result !== 'wait' && gamePrice.result"
      class="col mx-2 result text-center"
      :class="gamePrice.result !== 'wait' ? gamePrice.result : ''"
    >
      {{
        gamePrice.result === "home"
          ? "แดงชนะ"
          : gamePrice.result === "away"
          ? "น้ำเงินชนะ"
          : gamePrice.result === "draw"
          ? "เสมอ"
          : gamePrice.result === "cancel"
          ? "ยกเลิก"
          : ""
      }}
    </div>
    <div
      v-if="gamePrice.result === 'wait' || isLoading"
      class="col live-price red ms-3 me-2"
      style="line-height: 1.25 !important"
      :class="gamePrice.home_price_limit <= 0 ? 'disabled' : ''"
    >
      <div class="py-2">
        <div class="top" style="color: #fff">
          <!-- gamePrice.is_pause === false &&
            gamePrice.is_price_open === true &&
            gamePrice.home_price_limit > 0 -->
          {{ numberWithCommas(gamePrice.total_home_amount / 100, 0) }}
        </div>
        <div class="middle" style="color: #ffe248">
          {{
            //         ticketLists[gamePrice.id] !== undefined &&
            //         ticketLists[gamePrice.id].length > 0 &&
            //         gamePrice.is_price_open === false
            //           ? numberWithCommas(
            //               ticketLists[gamePrice.id][0].home_price.split("/")[0],
            //               2,
            //               true
            //             )
            // :
            numberWithCommas(
              calculatePercentageReward(
                gamePrice.total_home_amount / 100,
                gamePrice.total_away_amount / 100,
                commission
              ),
              2,
              true
            )
          }}
        </div>
        <div class="bottom pt-2">
          <button
            @click="$emit('handleClickBetRed')"
            class="btn btn-white text-red"
            :class="gamePrice.is_price_open === false ? 'close' : ''"
            data-bs-dismiss="modal"
            :data-bs-toggle="
              gamePrice.is_price_open === true && gamePrice.is_pause === false
                ? 'modal'
                : ''
            "
            data-bs-target="#numpadModal"
            :disabled="gamePrice.home_price_limit <= 0 ? true : false"
          >
            {{ gamePrice.is_price_open === true ? "แทงฝั่งแดง" : "ปิดรับ" }}
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="gamePrice.result === 'wait' || isLoading"
      class="col live-price blue ms-2 me-3"
      style="line-height: 1.25 !important"
      :class="gamePrice.away_price_limit <= 0 ? 'disabled' : ''"
    >
      <div class="py-2">
        <div class="top" style="color: #fff">
          {{ numberWithCommas(gamePrice.total_away_amount / 100, 0) }}
        </div>
        <div class="middle" style="color: #ffe248">
          {{
            //         ticketLists[gamePrice.id] !== undefined &&
            //         ticketLists[gamePrice.id].length > 0 &&
            //         gamePrice.is_price_open === false
            //           ? numberWithCommas(
            //               ticketLists[gamePrice.id][0].away_price.split("/")[0],
            //               2,
            //               true
            //             )
            // :
            numberWithCommas(
              calculatePercentageReward(
                gamePrice.total_away_amount / 100,
                gamePrice.total_home_amount / 100,

                commission
              ),
              2,
              true
            )
          }}
        </div>
        <div class="bottom pt-2">
          <button
            @click="$emit('handleClickBetBlue')"
            class="btn btn-white text-blue"
            :class="gamePrice.is_price_open === false ? 'close' : ''"
            data-bs-dismiss="modal"
            :data-bs-toggle="
              gamePrice.is_price_open === true && gamePrice.is_pause === false
                ? 'modal'
                : ''
            "
            data-bs-target="#numpadModal"
            :disabled="gamePrice.away_price_limit <= 0 ? true : false"
          >
            {{ gamePrice.is_price_open === true ? "แทงฝั่งน้ำเงิน" : "ปิดรับ" }}
          </button>
        </div>
      </div>
    </div>
    <div class="pool-info d-flex justify-content-between py-2 px-3">
      <div>ใช้อัตราต่อรองสุดท้ายก่อนปิด</div>
      <div @click="$emit('handleClickInfo')" class="cursor-pointer">
        <i class="fas fa-question-circle"></i>&nbsp;ข้อมูลเพิ่มเติม
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from "vue";
import { utilily } from "@/utilities/utility";

export default {
  props: [
    "gamePrice",
    "ticketLists",
    "commission",
    "isHiddenContent",
    "approveTime",
    "gameObj",
    "isLoading",
    "textPulse",
  ],
  setup(props) {
    const { numberWithCommas, calculatePercentageReward } = utilily();
    const state = reactive({});
    onMounted(() => {
      console.log(
        "POOL",
        numberWithCommas(
          calculatePercentageReward(458713, 457002, props.commission),
          2,
          true
        )
      );
    });

    return {
      ...toRefs(state),
      numberWithCommas,
      calculatePercentageReward,
    };
  },
};
</script>

<style lang="scss" scoped></style>
