import { request } from "./api";

export function login(data) {
  const url = `/player/login`;
  return request("post", url, {}, false, data);
}

export function getToken(data) {
  const url = `/player/temcus/${data}`;
  return request("get", url, {}, false);
}

export function getProfile() {
  const url = `/player/profile`;
  return request("get", url, {});
}
