import { request } from "./api";

export function getGamePrice(data) {
  const url = `/player/game-price/${data}`;
  return request("get", url, {});
}

export function sendTicket(data) {
  const url = `/player/sendticket`;
  return request("post", url, data);
}

export function getTicket(data) {
  const url = `/player/ticket-gameprice/${data}`;
  return request("get", url, {});
}

export function getHistory(data) {
  const url = `/player/history-gamelist`;
  return request("post", url, data);
}

export function getLiveToken(league_id, game_price_id) {
  const url = `/player/livetoken`;
  return request("post", url, {
    league_id: parseInt(league_id),
    game_price_id: parseInt(game_price_id),
  });
}

export function getCommission(data) {
  const url = `/player/commission/${data}`;
  return request("get", url, {});
}

export function getPool(data) {
  const url = `/player/pool/${data}`;
  return request("get", url, {});
}
