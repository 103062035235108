import Swal from "sweetalert2";

export function swalAlert() {
  const swalAlt = Swal.mixin({
    customClass: {
      container: "swal-container",
      popup: "swal-popup",
      title: "swal-title",
      htmlContainer: "swal-html-container",
      actions: "swal-actions",
      confirmButton: "swal-confirm-btn",
      cancelButton: "swal-cancel-btn",
    },
  });
  return { swalAlt };
}
