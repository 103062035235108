<template>
  <div class="container position-relative bg-grey">
    <Header icon-left="arrow" />
    <router-view></router-view>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";

export default {
  name: "GameLayout",
  components: {
    Header,
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
