<template>
  <div class="row g-2 mt-2">
    <div
      v-for="(streamer, index) in streamers"
      :key="index"
      :class="index > 2 ? 'col-6' : 'col-12'"
    >
      <div
        @click="handleClickStreamer(streamer.id)"
        class="position-relative"
        :class="streamer.image === null ? 'streamer' : ''"
      >
        <div v-if="streamer.image === null" class="res16-9-1">
          {{ streamer.name }}
        </div>
        <img v-else class="streamer res16-9-2" :src="streamer.image" alt="" />
        <div v-if="streamer.is_live === true" class="live-icon home">
          <LiveIcon :id="'lavContainer' + index" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs } from "vue";
import { useStreamer } from "./composable/use-streamer";
import LiveIcon from "@/components/LiveIcon.vue";

export default {
  components: {
    LiveIcon,
  },
  setup() {
    const { state, handleClickStreamer } = useStreamer();

    return {
      ...toRefs(state),
      handleClickStreamer,
    };
  },
};
</script>

<style lang="scss" scoped>
.streamer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "JamjureeRegular";
  background-color: black;
  color: white;
  border-radius: 0.75rem;
  cursor: pointer;
}
</style>
