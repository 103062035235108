import axios from "axios";
import router from "@/router/index";
import { request_demo, isDemoMode } from "./api-demo";

const BASE_API_URL = process.env.VUE_APP_API_URL
  ? process.env.VUE_APP_API_URL
  : "https://api-dev.muaysod.com";
console.log("url", BASE_API_URL);
const http = axios.create({
  baseURL: BASE_API_URL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    // Referer: "player.muaysod.com",
  },
});

export async function request(method, url, data, csrf = false, token = null) {
  if (isDemoMode()) {
    return request_demo(url, data);
  }
  var response;
  let headers = {};
  if (token) {
    const header_auth = {
      Authorization: "Bearer " + token,
    };

    headers = {
      ...header_auth,
    };
  }
  try {
    if (csrf) {
      await http.get(`/sanctum/csrf-cookie`).then(async () => {
        response = await http({
          method,
          url,
          data,
          headers,
        });
      });
    } else {
      response = await http({
        method,
        url,
        data,
        headers,
      });
    }
  } catch (error) {
    console.log("error ->>", error.response);
    if (error.response.status === 401) {
      router.replace({ name: "Error" });
    }
  }
  if (!response.data.status) {
    if (response.data.message === "กรุณาเข้าสู่ระบบใหม่อีกครั้ง.") {
      router.replace({ name: "Error" });
    }
  }
  // console.log(response);
  return response.data;
}
