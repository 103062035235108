<template>
  <div
    class="container position-relative overflow-auto"
    :class="route.name === 'Streamer' ? '' : 'bg-grey'"
  >
    <Sidebar />
    <Header icon-left="sort" />
    <!-- <div class="dialog-loggedin" id="dialog-loggedin">
      <div class="text-center">
        <i class="fas fa-check-circle"></i> ลงชื่อเข้าใช้เรียบร้อย
      </div>
    </div> -->

    <router-view></router-view>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Sidebar from "@/components/Sidebar.vue";
import { onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useMobileDetection } from "vue3-mobile-detection";

export default {
  name: "DefaultLayout",
  components: {
    Header,
    Sidebar,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const { isMobile } = useMobileDetection();
    onMounted(() => {
      console.log(route.name);
      const isLoggedIn = localStorage.getItem("isLoggedIn");
      if (isLoggedIn && isLoggedIn === "true") {
        store.commit("defaultModule/SET_IsLoggedIn", true);
      } else {
        store.commit("defaultModule/SET_IsLoggedIn", false);
      }
    });
    return { route, isMobile };
  },
};
</script>

<style lang="scss" scoped></style>
