<template>
  <div>
    <audio id="streamListener" playsinline autoplay></audio>
    <audio id="dealerListener" playsinline autoplay></audio>
  </div>
</template>

<script>
import { onBeforeUnmount, onMounted, reactive, toRefs, watch } from "vue";
import { RoomEvent, Room, Track } from "livekit-client";
import { getLiveToken } from "@/services/price-service";
import { useRoute } from "vue-router";

export default {
  name: "AudioPlayer",
  props: [
    "showAudio",
    "playerType",
    "showVideo",
    "wss_stream_url",
    "leagueId",
    "isVolumeMute",
  ],

  setup(props, context) {
    const route = useRoute();
    const state = reactive({
      room: undefined,
      playerType: "",
      isShowVideo: false,
      wss_stream_url: null,
      vdoPublisher: "",
      currentAudioTrack: null,
      isInit: true,
      attachedStreamListener: false,
      attachedDealerListener: false,
    });

    onBeforeUnmount(() => {
      stopStream();
    });

    onMounted(() => {
      // setSldpRoom();
    });
    watch(
      () => [
        props.showAudio,
        props.playerType,
        props.showVideo,
        props.wss_stream_url,
        props.isVolumeMute,
      ],
      async (
        [showAudio, playerType, showVideo, wss_stream_url, isVolumeMute],
        [prevShowAudio]
      ) => {
        state.playerType = playerType;
        state.isShowVideo = showVideo;
        state.wss_stream_url = wss_stream_url;
        if (playerType === "SLDP") {
          if (showAudio !== prevShowAudio) {
            if (showAudio === false) {
              stopStream();
              state.attachedStreamListener = false;
            } else {
              if (state.room === undefined) setSldpRoom();
              startLiveSound();
            }
          }
        } else if (playerType === "WEBRTC") {
          if (showAudio !== prevShowAudio) {
            if (showAudio === false) {
              setMuteListener("dealerListener");
            } else {
              if (state.room === undefined) await setWebRtcRoom();
              setMuteListener("streamListener");
            }
          }
          if (showVideo) {
            if (state.room === undefined) await setWebRtcRoom();
            else setAttachVideo(true);
          } else {
            if (state.room !== undefined) setAttachVideo(false);
          }
          if (isVolumeMute === true) {
            setMuteListener("all");
          } else {
            if (showAudio === false) {
              setMuteListener("dealerListener");
            } else {
              if (state.room === undefined) await setWebRtcRoom();
              setMuteListener("streamListener");
            }
          }
        }
      }
    );

    const setSldpRoom = () => {
      console.log(`setSldpRoom with playerType - ${props.playerType}`);
      state.room = new Room();
      state.room
        .on(RoomEvent.SignalConnected, async () => {
          const participantIdentity = `L${props.leagueId}:GP${route.params.id}`;
          const participant =
            state.room.getParticipantByIdentity(participantIdentity);
          const trackPub =
            participant !== undefined
              ? participant.getTrack(Track.Source.Microphone)
              : undefined;
          if (trackPub !== undefined) {
            trackPub.setSubscribed(true);
          } else {
            console.log(`can't get audio of ${participantIdentity}`);
          }
        })
        .on(RoomEvent.TrackPublished, (publication, participant) => {
          console.log(publication, participant);
          console.log("Participant TrackPublished");
          const participantIdentity = `L${props.leagueId}:GP${route.params.id}`;
          if (participant.identity === participantIdentity)
            publication.setSubscribed(true);
        })
        .on(RoomEvent.TrackSubscribed, (_, _2, participant) => {
          console.log("Participant Subscribed");
          const micPub = participant.getTrack(Track.Source.Microphone);
          const audioELm = document.getElementById("streamListener");
          if (
            !state.attachedDealerListener &&
            micPub !== undefined &&
            micPub.audioTrack !== undefined
          ) {
            state.attachedDealerListener = true;
            micPub.audioTrack.attach(audioELm);
            console.log(`attach participant ${participant.identity} to audio`);
          }
        })
        .on(RoomEvent.TrackUnpublished, (publication, participant) => {
          console.log("track unpublished");
          const participantIdentity = `L${props.leagueId}:GP${route.params.id}`;
          if (participant.identity === participantIdentity)
            publication.setSubscribed(false);
        })
        .on(RoomEvent.TrackUnsubscribed, (track, _, participant) => {
          console.log("track unsubscribed");

          const audioELm = document.getElementById("streamListener");
          state.attachedDealerListener = false;
          track.detach(audioELm);
          console.log(
            `detach participant ${participant.identity} from audio element`
          );
        })
        .on(RoomEvent.Disconnected, (reason) => {
          console.log("Disconnected from room due to");
          console.log(reason);
        });
    };
    const stopStream = () => {
      try {
        state.room.disconnect(true);
        console.log("disconect audio");
      } catch {
        console.log("error Audio");
      }
    };
    const startLiveSound = async () => {
      console.log("start live sound!");
      context.emit("isLoading", true);

      const listenerToken = await getLiveToken(props.leagueId, route.params.id);
      // console.log("Listener Token ", listenerToken);
      const token = listenerToken.data.token;
      const url = process.env.VUE_APP_LIVEKIT_URL
        ? process.env.VUE_APP_LIVEKIT_URL
        : "wss://livekit-test.muaysod.com";
      const roomConnectOpt = {
        autoSubscribe: false,
      };
      try {
        await state.room.connect(url, token, roomConnectOpt);
        context.emit("isLoading", false);
      } catch (error) {
        context.emit("isLoading", false);
        console.log("error ", error);
      }
    };
    const setWebRtcRoom = async () => {
      state.room = new Room();
      state.room
        .on(RoomEvent.SignalConnected, () => {
          console.log("connected! webrtc video");
          setTimeout(() => {
            context.emit("isConnect");
          }, 3000);
          const streamerIdentity = `L${props.leagueId}`;
          const dealerIdentity = `L${props.leagueId}:GP${route.params.id}`;

          // get both participant (streamer and dealer)
          const streamer =
            state.room.getParticipantByIdentity(streamerIdentity);
          const dealer = state.room.getParticipantByIdentity(dealerIdentity);
          // get track(s) from both streamer has camera and microphone
          const streamerTracks =
            streamer !== undefined ? streamer.getTracks() : undefined;
          if (streamerTracks !== undefined) {
            streamerTracks.forEach((track) => {
              track.setSubscribed(true);
            });
          } else {
            console.log(
              `can't get streaming vid and sound of ${streamerIdentity}`
            );
          }

          // dealer has only microphone
          const dealerTracks =
            dealer !== undefined
              ? dealer.getTrack(Track.Source.Microphone)
              : undefined;
          if (dealerTracks !== undefined) {
            dealerTracks.setSubscribed(true);
          } else {
            console.log(
              `can't get streaming vid and sound of ${dealerIdentity}`
            );
          }
        })
        .on(RoomEvent.TrackPublished, (publication, participant) => {
          const streamerIdentity = `L${props.leagueId}`;
          const dealerIdentity = `L${props.leagueId}:GP${route.params.id}`;
          if (
            participant.identity === streamerIdentity ||
            participant.identity === dealerIdentity
          )
            publication.setSubscribed(true);
        })
        .on(RoomEvent.TrackSubscribed, (_track, _publication, participant) => {
          console.log("Participant Subscribed");
          renderParticipant(participant);
        })
        .on(RoomEvent.TrackUnpublished, (publication, participant) => {
          console.log("track unpublished");
          const streamerIdentity = `L${props.leagueId}`;
          const dealerIdentity = `L${props.leagueId}:GP${route.params.id}`;
          if (
            participant.identity === streamerIdentity ||
            participant.identity === dealerIdentity
          )
            publication.setSubscribed(false);
        })
        .on(RoomEvent.TrackUnsubscribed, (track, _, participant) => {
          console.log("track unsubscribed");
          const streamerIdentity = `L${props.leagueId}`;
          const dealerIdentity = `L${props.leagueId}:GP${route.params.id}`;
          // streamer got video and audio track
          if (participant.identity === streamerIdentity) {
            if (track.kind === "video") {
              const videoElm = document.querySelector(
                ".live-cdn.bg-dark > video"
              );
              track.detach(videoElm);
              console.log(
                `detach participant ${participant.identity} from video element`
              );
            } else if (track.kind === "audio") {
              const audioELm = document.getElementById("dealerListener");
              state.attachedStreamListener = false;
              track.detach(audioELm);
              console.log(
                `detach participant ${participant.identity} from audio element`
              );
            }
          }
          // dealer got only audio track
          else if (participant.identity === dealerIdentity) {
            const audioELm = document.getElementById("streamListener");
            state.attachedDealerListener = false;
            track.detach(audioELm);
            console.log(
              `detach participant ${participant.identity} from audio element`
            );
          }
        });

      const listenerToken = await getLiveToken(props.leagueId, route.params.id);

      const roomConnectOpt = {
        autoSubscribe: false,
      };
      const token = listenerToken.data.token;
      const url = state.wss_stream_url
        ? state.wss_stream_url
        : "wss://livekit-test.muaysod.com";
      try {
        await state.room.connect(url, token, roomConnectOpt);
        context.emit("isConnect");
      } catch (error) {
        // context.emit("isLoading", false);
        console.log("error----> ", error);
      }
    };
    const renderParticipant = (participant) => {
      const streamerIdentity = `L${props.leagueId}`;
      const dealerIdentity = `L${props.leagueId}:GP${route.params.id}`;
      //streamer : Camera + Microphone
      if (streamerIdentity === participant.identity) {
        const cameraPub = participant.getTrack(Track.Source.Camera);
        const micPub = participant.getTrack(Track.Source.Microphone);
        const videoElm = document.querySelector(".live-cdn.bg-dark > video");
        const audioELm = document.getElementById("streamListener");

        console.log(`participant = ${participant.identity}`);
        if (cameraPub !== undefined && cameraPub.videoTrack !== undefined) {
          console.log(`cameraPub = ${cameraPub}`);
          console.log(`attach participant ${participant.identity} to video`);
          cameraPub.videoTrack.attach(videoElm);
        }

        if (micPub !== undefined && micPub.audioTrack !== undefined) {
          console.log(`micPub = ${micPub}`);
          console.log(`attach participant ${participant.identity} to audio`);
          if (!state.attachedStreamListener) {
            console.log(
              `attach participant ${participant.identity} to audio as streamer`
            );
            state.attachedStreamListener = true;
            micPub.audioTrack.attach(audioELm);
          }
        }
      }
      //dealer : Microphone
      else if (dealerIdentity === participant.identity) {
        const micPub = participant.getTrack(Track.Source.Microphone);
        const audioELm = document.getElementById("dealerListener");
        if (micPub !== undefined && micPub.audioTrack !== undefined) {
          if (!state.attachedDealerListener) {
            console.log(
              `attach participant ${participant.identity} to audio as dealer`
            );
            state.attachedStreamListener = true;
            micPub.audioTrack.attach(audioELm);
          }
        }
      }
    };
    const setAttachVideo = (setAttach) => {
      const videoElm = document.querySelector(".live-cdn.bg-dark > video");
      try {
        if (setAttach) {
          state.room
            .getParticipantByIdentity(`L${props.leagueId}`)
            .getTrack(Track.Source.Camera)
            .videoTrack.attach(videoElm);
        } else {
          state.room
            .getParticipantByIdentity(`L${props.leagueId}`)
            .getTrack(Track.Source.Camera)
            .videoTrack.detach(videoElm);
        }
      } catch (e) {
        console.log(e);
      }
    };
    const setMuteListener = (element) => {
      let streamListener = document.getElementById("streamListener");
      let dealerListener = document.getElementById("dealerListener");
      if (element === "streamListener") {
        streamListener.muted = true;
        dealerListener.muted = false;
      } else if (element === "dealerListener") {
        streamListener.muted = false;
        dealerListener.muted = true;
      } else {
        streamListener.muted = true;
        dealerListener.muted = true;
      }
    };
    const mute = (mode) => {
      console.log("MUTE");
      var vid;
      if (mode === true) {
        vid = document.getElementById("streamListener");
        vid.muted = true;
      } else {
        vid = document.getElementById("streamListener");
        vid.muted = false;
      }
    };
    return {
      ...toRefs(state),
      setSldpRoom,
      stopStream,
      startLiveSound,
      mute,
    };
  },
};
</script>

<style lang="scss" scoped></style>
