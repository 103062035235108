import { computed, onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getStreamer } from "@/services/streamer-service";

export function useStreamer() {
  const router = useRouter();
  const store = useStore();
  const state = reactive({
    streamers: [],
    webSocket: computed(() => store.state.priceModule.webSocket),
  });
  onMounted(async () => {
    localStorage.removeItem("streamer");
    store.commit("defaultModule/SET_IsStreamer", null);
    const response = await getStreamer();
    if (response.status) {
      var sort = response.data.sort((a, b) => {
        if (a.position === null && b.position === null) return 0;
        if (a.position === null) return 1;
        if (b.position === null) return -1;
        return a.position - b.position;
      });
      state.streamers = sort;
      console.log(state.streamers);
    }
  });

  const handleClickStreamer = async (id) => {
    localStorage.setItem("streamer", id);
    store.commit("defaultModule/SET_IsStreamer", id);
    await router.push({ name: "Match", params: { id: id } });
  };
  return { state, handleClickStreamer };
}
