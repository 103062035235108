import { h, resolveComponent } from "vue";
import Match from "@/views/match/Match.vue";
import Streamer from "@/views/streamer/Streamer.vue";
import History from "@/views/history/History.vue";
import Transaction from "@/views/transaction/Transaction.vue";

const defaultRoutes = [
  {
    path: "",
    name: "DefaultRoute",
    component: {
      render: () => h(resolveComponent("router-view")),
    },
    children: [
      {
        path: "",
        name: "Streamer",
        component: Streamer,
      },
      {
        path: "/history",
        name: "History",
        component: History,
      },
      {
        path: "/transaction",
        name: "Transaction",
        component: Transaction,
      },
      {
        path: "/matchlist/:id",
        name: "Match",
        component: Match,
        meta: { keepWSS: true },
      },
    ],
  },
];

export default defaultRoutes;
