import { getGamePrice, getTicket, getHistory } from "@/services/price-service";
import { getProfile } from "@/services/auth-service";
import { io } from "socket.io-client";
import { isDemoMode } from "@/services/api-demo";

const priceModule = {
  namespaced: true,
  state: {
    gamePrice: {},
    profile: {},
    ticketLists: {},
    historyLists: [],
    multipleRed: 0,
    multipleBlue: 0,
    isLastHistory: false,
    isHistoryOpen: false,
    webSocket: {},
    socketURL: "",
  },
  mutations: {
    SET_GAMEPRICE_DATA(state, newGamePrice) {
      state.gamePrice = newGamePrice;
    },
    SET_GAMEPRICE_TOTAL_POOL_DATA(state, newData) {
      state.gamePrice.total_home_amount = newData.total_home_amount;
      state.gamePrice.total_away_amount = newData.total_away_amount;
    },
    SET_PIN_TEXT_DATA(state, newData) {
      state.gamePrice.pin_text = newData.pin_text;
    },
    SET_GAMEPRICE_TOTAL_DATA(state, newData) {
      const multiple = newData.mode !== "pool" ? 2 : 1;
      if (newData.bet_team === "home") {
        state.gamePrice.total_home_amount =
          state.gamePrice.total_home_amount + newData.amount * multiple;
      } else {
        state.gamePrice.total_away_amount =
          state.gamePrice.total_away_amount + newData.amount * multiple;
      }
    },
    SET_PROFILE_DATA(state, newProfile) {
      state.profile = newProfile;
    },
    SET_BALANCE_DATA(state, newBalance) {
      state.profile.balance = newBalance;
    },
    SET_TICKETLISTS_DATA(state, { newTicketLists, id, remove = false }) {
      if (remove) {
        if (state.ticketLists[id]) {
          delete state.ticketLists[id];
        }
      } else {
        state.ticketLists[id] = newTicketLists[id];
      }
    },
    SET_ISHISTORYOPEN_DATA(state, newIsHistoryOpen) {
      state.isHistoryOpen = newIsHistoryOpen;
    },
    SET_HISTORYPUSH_DATA(state, newHistoryLists) {
      state.historyLists.push(newHistoryLists);
    },
    SET_HISTORYLISTS_DATA(state, newHistoryList) {
      state.historyLists = newHistoryList;
    },
    SET_ISLASTHISTORY_DATA(state, newIsLastHistory) {
      state.isLastHistory = newIsLastHistory;
    },
    SET_PRICETYPE_DATA(state, newPriceType) {
      state.priceType = newPriceType;
    },
    SET_MULTIPLERED_DATA(state, newMultipleRed) {
      if (newMultipleRed === null) {
        return;
      }
      const Arr = newMultipleRed.split("/");
      const multiple = Arr[0] / Arr[1];
      state.multipleRed = multiple;
    },
    SET_MULTIPLEBLUE_DATA(state, newMultipleBlue) {
      if (newMultipleBlue === null) {
        return;
      }
      const Arr = newMultipleBlue.split("/");
      const multiple = Arr[0] / Arr[1];
      state.multipleBlue = multiple;
    },
    // async SET_SOCKETURL_DATA(state) {
    //   let result = await axios.get(
    //     process.env.VUE_APP_BALANCER_URL
    //       ? process.env.VUE_APP_BALANCER_URL
    //       : "https://wss-bal.muaysod.com/fetch/"
    //   );
    //   console.log(result.data.data.url);
    //   if (result.data.data.url) state.socketURL = result.data.data.url;
    //   else {
    //     console.log("skip fetching url.");
    //     state.socketURL = "wss-p01.muaysod.com";
    //   }
    //   console.log("socket URL", state.socketURL);
    // },
    SET_WEBSOCKET_DATA(
      state,
      { url, streamerId, customer_id, disconnect = false }
    ) {
      if (disconnect) {
        if (state.webSocket.connected === true) {
          state.webSocket.disconnect();
        }
        state.webSocket = {};
        console.log("disconnect");
        return;
      }
      console.log(streamerId, state.profile.username);
      let connected = state.webSocket.connected;
      if (connected === true) {
        console.log("fail wss");
        return;
      }
      console.log("success wss");
      console.log(`in SET_WEBSOCKET_DATA state.socketURL = ${url}`);
      state.webSocket = io(url, {
        transports: ["websocket"],
        query: {
          customer_id: customer_id,
          stream_id: streamerId,
          // ตอนนี้ใช้ 1 ไปก่อน เดี๋ยวค่อยเปลี่ยนตาม stream_id จริงๆ
          // stream_id: state.gameId.toString(),
        },
        autoConnect: isDemoMode() ? false : true,
        // reconnectionAttempts: 4,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
      });
    },
  },
  actions: {
    checkPrice(context, price) {
      if (price.is_price_open && price.is_price_open === false) {
        return null;
        // context.commit("SET_PRICETYPE_DATA", null);
      } else if (price.home_price === "9/10" && price.away_price === "9/10") {
        return "bar";
        // context.commit("SET_PRICETYPE_DATA", "bar");
      } else if (
        (price.home_price === "8/10" && price.away_price === "10/10") ||
        (price.home_price === "10/10" && price.away_price === "8/10")
      ) {
        return "draw";
        // context.commit("SET_PRICETYPE_DATA", "draw");
      } else {
        return "upper";
        // context.commit("SET_PRICETYPE_DATA", "upper");
      }
    },
    async getGamePrice(context, { id, wss, data = null }) {
      let response = {};
      if (!wss) {
        response = await getGamePrice(id);
      } else {
        response.data = data;
        response.status = true;
      }
      if (response.status) {
        // let newGamePrice = response.data;
        let newGamePrice = JSON.parse(JSON.stringify(response.data));
        const priceType = await this.dispatch(
          "priceModule/checkPrice",
          newGamePrice
        );
        newGamePrice.home_price_limit = response.data.home_price_limit / 100;
        newGamePrice.away_price_limit = response.data.away_price_limit / 100;
        if (newGamePrice.mode !== "pool") {
          newGamePrice.total_home_amount = response.data.total_home_amount * 3;
          newGamePrice.total_away_amount = response.data.total_away_amount * 3;
        }
        newGamePrice.bet_min = response.data.bet_min / 100;
        newGamePrice.priceType = priceType;
        console.log("getGamePrice", newGamePrice);
        context.commit("SET_GAMEPRICE_DATA", newGamePrice);
        context.commit("SET_MULTIPLERED_DATA", newGamePrice.home_price);
        context.commit("SET_MULTIPLEBLUE_DATA", newGamePrice.away_price);
      } else {
        console.log("fail");
      }
    },
    async getProfile(context) {
      const response = await getProfile();
      if (response.status) {
        var newProfile = response.data;
        newProfile.balance = newProfile.balance / 100;
        console.log("getProfile", newProfile);
        context.commit("SET_PROFILE_DATA", newProfile);
      }
    },
    async getAllTicket(context, id) {
      const response = await getTicket(id);
      if (response.status) {
        var newTicketLists = response.data;
        for (let index = 0; index < newTicketLists.length; index++) {
          newTicketLists[index].amount = newTicketLists[index].amount / 100;
          newTicketLists[index].reward = newTicketLists[index].reward / 100;
          const priceType = await this.dispatch(
            "priceModule/checkPrice",
            newTicketLists[index]
          );
          newTicketLists[index].priceType = priceType;
        }
        return newTicketLists;
      }
      return [];
    },
    // async getTicket(context, id) {
    //   var newTicketLists = {};
    //   newTicketLists[id] = await this.dispatch("priceModule/getAllTicket", id);
    //   // newTicketLists[id] = newTicketLists[id].reverse();
    //   console.log("getTicket", newTicketLists);
    //   context.commit("SET_TICKETLISTS_DATA", { newTicketLists, id });
    // },
    async getTicket(context, { id, data = null }) {
      var newTicketLists = {};
      if (data !== null) {
        newTicketLists[id] = data;
        const priceType = await this.dispatch(
          "priceModule/checkPrice",
          newTicketLists[id]
        );
        for (let index = 0; index < newTicketLists[id].length; index++) {
          newTicketLists[id][index].priceType = priceType;
        }

        console.log("data ticket", newTicketLists[id]);
        context.commit("SET_TICKETLISTS_DATA", { newTicketLists, id });
      } else {
        var oldRound;
        newTicketLists[id] = await this.dispatch(
          "priceModule/getAllTicket",
          id
        );
        // newTicketLists[id] = newTicketLists[id].reverse();
        newTicketLists[id].totalBet = 0;
        newTicketLists[id].result = 0;
        for (let j = 0; j < newTicketLists[id].length; j++) {
          newTicketLists[id].totalBet =
            newTicketLists[id].totalBet + newTicketLists[id][j].amount;
          if (newTicketLists[id][j].status === 600) {
            newTicketLists[id].result =
              newTicketLists[id].result +
              (newTicketLists[id][j].reward - newTicketLists[id][j].amount);
          } else if (
            newTicketLists[id][j].status === 650 ||
            newTicketLists[id][j].status === 690
          ) {
            newTicketLists[id].result =
              newTicketLists[id].result - newTicketLists[id][j].amount;
          }

          if (newTicketLists[id][j].round_id !== oldRound) {
            newTicketLists[id][j].isNewRound = true;
          } else {
            newTicketLists[id][j].isNewRound = false;
          }
          oldRound = newTicketLists[id][j].round_id;
        }
        console.log("getTicket", newTicketLists);
        context.commit("SET_TICKETLISTS_DATA", { newTicketLists, id });
      }
    },
    async getHistory(context, data) {
      var newHistoryLists = [];
      var req;
      if (data.id !== null) {
        req = { id: data.id, streamer_id: data.streamer_id };
      } else {
        req = { streamer_id: data.streamer_id };
      }
      if (data.lastHistory === false) {
        const response = await getHistory(req);
        if (response.status) {
          console.log("HISTORY DATA", response);
          if (response.data.length === 0) {
            context.commit("SET_ISLASTHISTORY_DATA", true);
            return;
          }
          // newHistoryLists = response.data;
          for (let index = 0; index < response.data.length; index++) {
            newHistoryLists = response.data[index];
            // console.log("getHistory", newHistoryLists);
            context.commit("SET_HISTORYPUSH_DATA", newHistoryLists);
          }
        }
      }
    },
  },
};

export default priceModule;
