import { computed, onMounted, reactive, watch } from "vue";
import { getTransaction } from "@/services/transaction-service";
import { useStore } from "vuex";
import { utilily } from "@/utilities/utility";

export function useTransaction() {
  const store = useStore();
  const { setHeightElement } = utilily();
  const state = reactive({
    profile: computed(() => store.state.priceModule.profile),
    transaction: [],
    isLastTransaction: true,
    selectTransaction: 0,
    date: null,
    selectDate: [],
    ogHeight: 0,
    format: "dd/MM/yyyy",
    showList: 0,
    fullMonths: [
      "มกราคม",
      "กุมภาพันธ์  ",
      "มีนาคม",
      "เมษายน",
      "พฤษภาคม",
      "มิถุนายน",
      "กรกฎาคม",
      "สิงหาคม",
      "กันยายน",
      "ตุลาคม",
      "พฤศจิกายน",
      "ธันวาคม",
    ],
  });
  onMounted(() => {
    setListHeight();
    currentTransaction();
  });
  watch(
    () => state.date,
    (date) => {
      console.log("date", date);
      handleUpdateDatetime();
    }
  );
  const setListHeight = () => {
    const height = setHeightElement([
      ".container",
      ".header",
      ".transaction-balance",
      ".transaction-tab",
    ]);
    const tabLast = document.querySelector("#tabLast");
    tabLast.style.height = `${height - 50}px`;
    const tabHistory = document.querySelector("#tabHistory");
    tabHistory.style.height = `${height - 50}px`;
    state.ogHeight = height - 50;
  };
  const currentTransaction = () => {
    const currentdate = new Date();
    var earlyMonth = new Date();
    earlyMonth = earlyMonth.setDate(earlyMonth.getDate() - 31);
    console.log(
      "date range",
      Math.round(earlyMonth / 1000),
      Math.round(currentdate.getTime() / 1000)
    );

    const data = {
      start_date: Math.round(earlyMonth / 1000),
      end_date: Math.round(currentdate.getTime() / 1000),
    };
    setTransaction(data);
  };
  const setTransaction = async (data) => {
    const response = await getTransaction(data);
    if (response.status === true) {
      state.transaction = response.data;
      state.transaction = state.transaction.reverse();
      var oldDate = 0;
      var indexList = 0;
      for (let index = 0; index < response.data.length; index++) {
        var date = new Date(response.data[index].created_at * 1000);
        state.transaction[index].fullDate = date;
        if (state.transaction[index].fullDate.getDate() !== oldDate) {
          indexList = index;
          state.transaction[indexList].list = 1;
          state.transaction[index].firstLine = true;
          oldDate = state.transaction[index].fullDate.getDate();
        } else {
          state.transaction[indexList].list++;
          state.transaction[index].firstLine = false;
        }
      }

      console.log(state.transaction);
    }
  };
  const handleClickRefresh = async () => {
    await store.dispatch("priceModule/getProfile");
  };
  const handleClickTab = (stateTab) => {
    state.isLastTransaction = stateTab;
  };
  const handleClickSelect = (stateSelected) => {
    const select = document.querySelector("#dropdownMenuButton");
    const dropDown = document.querySelector(".dropdown-menu");
    select.classList.remove("show");
    select.setAttribute("aria-expanded", "false");
    dropDown.classList.remove("show");
    state.selectTransaction = stateSelected;
  };
  const handleUpdateDatetime = async () => {
    const data = {
      start_date: state.date[0].getTime() / 1000,
      end_date: state.date[1].getTime() / 1000,
    };
    await setTransaction(data);
    state.selectDate = state.transaction;
    console.log(state.selectDate);
  };

  return {
    state,
    handleClickRefresh,
    handleClickTab,
    handleClickSelect,
    handleUpdateDatetime,
  };
}
