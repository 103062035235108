<template>
  <div class="container position-relative">
    <div class="loading">
      <span class="err-text">กรุณาเข้าสู่ระบบใหม่อีกครั้ง</span>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";

export default {
  setup() {
    const state = reactive({
      count: 0,
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss" scoped></style>
