import { createRouter, createWebHistory } from "vue-router";
import DefaultLayout from "../layouts/DefaultLayout.vue";
import defaultRoutes from "./default/default-route.js";
import GameLayout from "../layouts/GameLayout.vue";
import gameRoutes from "./game/game-route.js";
import Login from "../views/Login.vue";
import Maintenance from "../views/Maintenance.vue";
import Error from "../views/Error.vue";
import store from "../store";

var routes = [
  {
    path: "/",
    name: "DefaultLayout",
    component: DefaultLayout,
    // meta: { requireAuth: true },
    children: [...defaultRoutes],
  },
  {
    path: "/game",
    name: "GameLayout",
    component: GameLayout,
    // meta: { requireAuth: true },
    children: [...gameRoutes],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: Maintenance,
  },
  {
    path: "/error",
    name: "Error",
    component: Error,
  },
];

if (process.env.VUE_APP_LOCAL_DEV === "true") {
  routes.push({
    path: "/test",
    name: "Test",
    component: () => import("../views/Test.vue"),
  });
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from) => {
  // console.log("route", to);
  if (to.name !== "Game") {
    localStorage.setItem("RefPageCount", "0");
  }
  // console.log("Local", process.env.VUE_APP_LOCAL_DEV);
  localStorage.setItem("prevMod", from.name);
  if (to.meta.keepWSS !== true) {
    store.commit("priceModule/SET_WEBSOCKET_DATA", {
      streamerId: null,
      customer_id: null,
      disconnect: true,
    });
  }
});

export default router;
