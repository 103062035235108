import { computed, onBeforeUnmount, onMounted, reactive, watch } from "vue";
import { swalAlert } from "@/utilities/sweet-alert.js";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { sendTicket, getCommission, getPool } from "@/services/price-service";
import { getMatch } from "@/services/match-service";
import { utilily } from "@/utilities/utility";
import {
  isDemoMode,
  demoWss_updateGamePrice,
  demoWss_newTicket,
} from "@/services/api-demo";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { useToast } from "vue-toastification";
// import { getLiveToken } from "@/services/price-service";
// import { RoomEvent, Room } from "livekit-client";

const SLDP_PLAYER = "SLDP";
const WEBRTC_PLAYER = "WEBRTC";
export function useGame() {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const toast = useToast();
  const { swalAlt } = swalAlert();
  const { checkPrice, getSocketURL, isMobile, calculateAspectRatio, isEmpty } =
    utilily();
  var swalOption = {
    buttonsStyling: true,
    showCancelButton: true,
    showConfirmButton: false,
    html: "",
    cancelButtonText: "ปิด",
    cancelButtonColor: "#fff",
    background: "#ff7070",
  };
  var swalOption2 = {
    buttonsStyling: true,
    showCancelButton: true,
    showConfirmButton: true,
    html: "",
    reverseButtons: true,
    cancelButtonText: "ปิด",
    cancelButtonColor: "#fff",
    confirmButtonText: "ไปคู่ต่อไป",
    confirmButtonColor: "#fff",
    background: "#444444",
  };
  const state = reactive({
    socketURL: "",
    webSocket: computed(() => store.state.priceModule.webSocket),
    gamePrice: computed(() => store.state.priceModule.gamePrice),
    prevMod: localStorage.getItem("prevMod"),
    gameObj: {},
    ticketLists: computed(() => store.state.priceModule.ticketLists),
    historyLists: computed(() => store.state.priceModule.historyLists),
    profile: computed(() => store.state.priceModule.profile),
    multipleRed: computed(() => store.state.priceModule.multipleRed),
    multipleBlue: computed(() => store.state.priceModule.multipleBlue),
    isLastHistory: computed(() => store.state.priceModule.isLastHistory),
    isHistoryTab: false,
    isHistoryOpen: computed(() => store.state.priceModule.isHistoryOpen),
    videoPlayer: null,
    numpads: [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "remove",
      "0",
      "delete",
    ],
    poster: null,
    gameId: null,
    ticketArr: [],
    pluses: ["100", "500", "1000", "5000"],
    textPulse: false,
    isRed: false,
    isBlue: false,
    isSubmit: false,
    isModalOpen: false,
    isHiddenContent: false,
    isLoading: false,
    isShowChat: true,
    isVolumeMute: false,
    isShowVideo: false,
    isShowAudio: false,
    playerType: undefined,
    room: null,
    isShowPoster: true,
    isLoadingAudio: false,
    isLoadingSubmit: false,
    isBet: true,
    countTime: 0,
    countTimeShow: 0,
    countTimeIntv: null,
    needRefreshData: false,
    priceErr: {},
    ogHistiryHeight: 0,
    betPrice: "0",
    timeoutArr: [],
    audioState: "stage",
    wss_stream_url: null,
    demoWssIntv: null,
    demoWssData: {},
    approveTime: 0,
    commission: null,
    isShowName: false,
    wssConnectCount: 0,
    wssAttempt: 0,
    videoHeight: 0,
    isShowBtnPlay: false,
  });
  onBeforeUnmount(() => {
    var myModalEl = document.getElementById("numpadModal");
    myModalEl.removeEventListener("shown.bs.modal", handleModal);
    myModalEl.removeEventListener("hidden.bs.modal", handleModal);
    store.commit("priceModule/SET_GAMEPRICE_DATA", {});
    if (typeof state.webSocket.off === "function") {
      state.webSocket.off();
    }
    if (state.videoPlayer) {
      state.videoPlayer.dispose();
    }
    clearTimeout(state.hoverTo);
    clearInterval(state.countTimeIntv);
    clearInterval(state.demoWssIntv);
    clearInterval(window.wssConnectIntv);
  });
  onMounted(async () => {
    if (isDemoMode()) {
      state.demoWssIntv = setInterval(() => {
        demoWss(state.demoWssData);
      }, 1000);
    }
    handleRefreshPage();
    setVideoHeight();
    await initGameData();
    setHistoryHeight();
    const history = document.querySelector(".history-list");
    history.addEventListener("scroll", handleScroll);
    var myModalEl = document.getElementById("numpadModal");
    myModalEl.addEventListener("shown.bs.modal", handleModal);
    myModalEl.addEventListener("hidden.bs.modal", handleModal);
    eventRotateScreen();
  });
  watch(
    () => [state.gamePrice.is_price_open],
    ([is_price_open], [prevIs_price_open]) => {
      if (is_price_open !== prevIs_price_open) {
        console.log("SET HEIGHT");
        setTimeout(() => {
          setHistoryHeight();
        }, 500);
      }
    }
  );
  const handleRefreshPage = () => {
    var RefPageCount = localStorage.getItem("RefPageCount");
    RefPageCount = parseInt(RefPageCount) + 1;
    localStorage.setItem("RefPageCount", RefPageCount);
    console.log("RefPageCount", RefPageCount);
    if (RefPageCount > 1) {
      state.isShowBtnPlay = true;
    } else {
      state.isShowBtnPlay = false;
    }
  };
  const setVideoHeight = () => {
    const aspectRatio = calculateAspectRatio(
      screen.width < 540 ? screen.width : 540
    );
    state.videoHeight = aspectRatio.height;
    const videoIntv = setInterval(() => {
      let elem = document.querySelector(".live-cdn.bg-dark > video");
      if (elem) {
        clearInterval(videoIntv);
        elem.style.height = aspectRatio.height + "px";
      }
    }, 100);
  };
  const voiceActorMode = () => {
    if (state.gameObj.mode === "normal") {
      return true;
    } else {
      return false;
    }
  };
  const handleClickInfo = () => {
    swalOption.background = "#E9E9E9";
    swalOption.html = `
    <span style='color:#000;font-size: 16px'>ข้อมูลเพิ่มเติม</span>
    <br/><br/>
    <div style='color:#000;font-size: 12px;font-family:"JamjureeRegular";text-align: left;'>
      1. ราคาที่คุณได้จะเป็นราคาสุดท้ายก่อนการปิดรับ <br/>
      2. หากฝั่งใดฝั่งหนึ่งได้เปอร์เซ็นต์น้อยกว่า 105% เกมนั้นจะถูกยกเลิก <br/>
      3. ระบบจะยกเลิกการเดิมพันครั้งนั้นๆ หากสัญญาณภาพขาดหายในขณะที่กำลังตัดสินผลแพ้ชนะ
    </div>`;
    swalAlt.fire(swalOption);
  };
  const setAutoApproveTime = () => {
    if (
      state.gameObj.auto_approve_time > 0 &&
      state.gameObj.auto_approve_time !== null &&
      state.gamePrice.is_price_open === true &&
      state.gamePrice.last_change_price !== null
    ) {
      const dateNow = new Date();
      const approveTime =
        state.gameObj.auto_approve_time +
        state.gamePrice.last_change_price -
        dateNow.getTime() / 1000;
      console.log(
        "AUTO",
        approveTime,
        state.gameObj.auto_approve_time,
        state.gamePrice.last_change_price
      );
      if (approveTime > 0) {
        clearInterval(window.approveTimeIntv);
        state.approveTime = parseInt(approveTime);
        window.approveTimeIntv = setInterval(() => {
          state.approveTime--;
          if (
            state.approveTime <= 0 ||
            state.gamePrice.is_price_open === false
          ) {
            clearInterval(window.approveTimeIntv);
            state.approveTime = 0;

            console.log("BYE BYE");
          }
        }, 1000);
      }
    }
  };
  const isNoNameGame = () => {
    if (state.gameObj.total_round <= 2) {
      return true;
    } else {
      return false;
    }
  };
  const updateLastPool = (data) => {
    const obj = {
      total_home_amount: data.total_home,
      total_away_amount: data.total_away,
    };
    store.commit("priceModule/SET_GAMEPRICE_TOTAL_POOL_DATA", obj);
  };
  const initGameData = async (initFirst = true) => {
    state.isLoading = true;
    await store.dispatch("priceModule/getGamePrice", {
      id: route.params.id,
      wss: false,
    });
    console.log(state.gamePrice);
    if (isEmpty(state.gamePrice) === true) {
      router.replace({
        name: "Match",
        params: { id: localStorage.getItem("streamer") },
      });
    }
    state.gameId = route.params.id;
    state.isLoading = false;
    state.gameObj = state.gamePrice;
    if (initFirst === true) {
      console.log("INTI VIDEO");
      state.socketURL = await getSocketURL();
      await setVideoPlayer();
      initWebSocket();
    }
    if (state.gamePrice.mode === "pool") {
      const responseCom = await getCommission(state.gamePrice.game_id);
      if (responseCom.status === true) {
        state.commission = responseCom.data.commission;
      }
      const responsePool = await getPool(state.gameId);
      if (
        responsePool.status === true &&
        state.gamePrice.is_price_open === false
      ) {
        updateLastPool(responsePool.data);
      }
      console.log("commision", state.commission);
      console.log("pool", responsePool);
    }
    await store.dispatch("priceModule/getTicket", { id: route.params.id });
    state.poster = state.gamePrice.poster;
    state.countTime = state.gamePrice.ticket_delay_time;
    if (state.prevMod !== "undefined" || route.query.f !== undefined) {
      if (
        state.gamePrice.http_stream_url !== null &&
        state.gamePrice.status === "live"
      ) {
        setTimeout(() => {
          handleClickVideoIcon(true);
          handleClickAudioIcon();
        }, 1000);
      }
    }
    setAutoApproveTime();
  };
  const handleHoverVideo = (isHover, event) => {
    let elemLive = document.querySelector(".live-cdn");
    console.log("handleHoverVideo", isHover, event);
    if (event === "touchend") {
      clearTimeout(window.hoverTo);
      elemLive.classList.add("fadeIn");
      window.hoverTo = setTimeout(() => {
        elemLive.classList.add("fadeOut");
        elemLive.classList.remove("fadeIn");
        setTimeout(() => {
          elemLive.classList.remove("fadeOut");
        }, 1000);
      }, 5000);
    } else if (event !== "touchend" && isMobile() === false) {
      clearTimeout(window.hoverTo);
      if (isHover === true) {
        elemLive.classList.add("fadeIn");
      } else if (isHover === false) {
        window.hoverTo = setTimeout(() => {
          elemLive.classList.add("fadeOut");
          elemLive.classList.remove("fadeIn");
          setTimeout(() => {
            elemLive.classList.remove("fadeOut");
          }, 1000);
        }, 5000);
      }
    }
  };
  const setVideoPlayer = async () => {
    if (
      state.gamePrice.http_stream_url === null ||
      state.gamePrice.http_stream_url === undefined
    ) {
      return;
    }
    if (state.gamePrice.http_stream_url.match(/^(https:\/\/)[\s\S]*/g)) {
      state.playerType = SLDP_PLAYER;
      setVideoSLDP();
    }
    if (state.gamePrice.http_stream_url.match(/^(wss:\/\/)[\s\S]*/g)) {
      state.playerType = WEBRTC_PLAYER;
      state.wss_stream_url = state.gameObj.http_stream_url;
      // await setVideoWebRTC();
    }
  };
  const setVideoSLDP = () => {
    const videoOptions = {
      autoplay: false,
      controls: false,
      muted: true,
      sources: [
        {
          src: state.gameObj.http_stream_url,
          type: "application/x-mpegURL",
        },
      ],
    };
    const videoInv = setInterval(() => {
      const videoTag = document.querySelector(".live-cdn.bg-dark > video");
      // console.log(videoTag);
      if (videoTag) {
        clearInterval(videoInv);
        state.videoPlayer = videojs(
          videoTag,
          videoOptions,
          function onPlayerReady() {
            console.log("onPlayerReady");
            setHistoryHeight();
          }
        );
        state.videoPlayer.on("error", function () {
          console.log("vdo js error occured ", state.videoPlayer.error());
        });
        if (state.prevMod === "undefined") {
          state.videoPlayer.muted(false);
        }
      }
    }, 100);
  };
  // const setVideoWebRTC = async () => {
  //   state.room = new Room();
  //   state.room.on(
  //     RoomEvent.TrackSubscribed,
  //     (track, publication, participant) => {
  //       console.log(publication, participant);
  //       console.log("Participant Subscribed");
  //       console.log("track in setVideoWebRTC = ", track);
  //       if (track.kind === "video") {
  //         const videoTag = document.querySelector(".live-cdn.bg-dark > video");
  //         track.attach(videoTag);
  //       } else if (track.kind === "audio") {
  //         console.log(`{participant.identity =${participant}`);
  //         // state.trackArr.push(participant.identity, track);
  //       }
  //     }
  //   );
  //   state.room.on(
  //     RoomEvent.TrackPublished,
  //     (track, publication, participant) => {
  //       console.log(`use0game RoomEvent.TrackPublished track =${track}`);
  //       console.log(
  //         `use0game RoomEvent.TrackPublished publication =${publication}`
  //       );
  //       console.log(
  //         `use0game RoomEvent.TrackPublished participant =${participant}`
  //       );
  //       // publication.setSubscribed(true)
  //     }
  //   );
  //   const listenerToken = await getLiveToken(route.params.id);

  //   console.log("Listener Token ", listenerToken);
  //   const token = listenerToken.data.token;
  //   const url = state.gamePrice.http_stream_url
  //     ? state.gamePrice.http_stream_url
  //     : "wss://livekit-test.muaysod.com";
  //   // console.log(token, url);
  //   try {
  //     console.log("connect ", token);
  //     await state.room.connect(url, token);
  //     // context.emit("isLoading", false);
  //   } catch (error) {
  //     // context.emit("isLoading", false);
  //     console.log("error ", error);
  //   }
  // };
  const handleModal = () => {
    state.isModalOpen = !state.isModalOpen;
    if (!state.isModalOpen) {
      state.isRed = false;
      state.isBlue = false;
      state.isSubmit = false;
      state.priceErr = {};
    }
  };
  const handleScroll = async () => {
    const history = document.querySelector(".history-list");
    if (history.offsetHeight + history.scrollTop >= history.scrollHeight) {
      if (state.isHistoryTab) {
        const data = {
          lastHistory: state.isLastHistory,
          id: state.historyLists[state.historyLists.length - 1].id,
        };
        await store.dispatch("priceModule/getHistory", data);
      }
    }
  };
  const handleAudioLoading = (data) => {
    state.isLoadingAudio = data;
    console.log("audio loading", data);
  };
  const handleClickChatIcon = () => {
    state.isShowChat = !state.isShowChat;
  };
  const handleClickVolumeIcon = (event) => {
    state.isVolumeMute = event;
    if (state.playerType === SLDP_PLAYER) {
      state.videoPlayer.muted(event);
      console.log("MUTE", event, state.videoPlayer);
    }
  };
  const videoMuteControl = () => {
    if (state.playerType === SLDP_PLAYER) {
      if (state.isShowAudio === true) {
        state.videoPlayer.muted(true);
      } else {
        state.videoPlayer.muted(false);
      }
      console.log("mute", state.videoPlayer.muted());
    }
  };
  const handleClickVideoFullScreen = () => {
    if (state.isShowVideo === false) {
      return;
    }
    var video;
    if (state.playerType === SLDP_PLAYER) {
      video = state.videoPlayer;
    } else {
      video = document.querySelector(".live-cdn.bg-dark > video");
    }

    // Request fullscreen (for iOS)
    if (video.webkitEnterFullscreen) {
      video.webkitEnterFullscreen();
    } else if (video.requestFullscreen) {
      video.requestFullscreen();
    } else if (video.mozRequestFullScreen) {
      /* Firefox */
      video.mozRequestFullScreen();
    } else if (video.msRequestFullscreen) {
      /* IE/Edge */
      video.msRequestFullscreen();
    }
    // if (video.requestFullscreen) {
    //   video.requestFullscreen();
    // } else if (video.mozRequestFullScreen) {
    //   video.mozRequestFullScreen();
    // } else if (video.webkitEnterFullscreen) {
    //   video.webkitEnterFullscreen();
    // } else if (video.msRequestFullscreen) {
    //   video.msRequestFullscreen();
    // }
  };
  const eventRotateScreen = () => {
    // Listen for the device orientation change event
    window.addEventListener("orientationchange", function () {
      var video;
      if (state.playerType === SLDP_PLAYER) {
        video = state.videoPlayer;
      } else {
        video = document.querySelector(".live-cdn.bg-dark > video");
      }
      console.log("ROTATE");
      // Check if the device is in landscape orientation
      if (window.orientation === 90 || window.orientation === -90) {
        // Request fullscreen for the video element
        if (video.requestFullscreen) {
          video.requestFullscreen();
        } else if (video.mozRequestFullScreen) {
          video.mozRequestFullScreen();
        } else if (video.webkitRequestFullscreen) {
          video.webkitRequestFullscreen();
        } else if (video.msRequestFullscreen) {
          video.msRequestFullscreen();
        }
      } else {
        // Exit fullscreen
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
    });
  };
  const handleClickVideoIcon = (event, isClickPlay = false) => {
    console.log("video", event);
    if (isClickPlay === true) {
      state.isShowBtnPlay = false;
      if (state.isShowVideo === true) {
        state.isShowVideo = false;
      }
    }
    if (event === true) {
      console.log("play");
      if (state.playerType === SLDP_PLAYER) state.videoPlayer.play();
      state.isShowPoster = false;
    } else {
      console.log("stop");
      if (state.playerType === SLDP_PLAYER) state.videoPlayer.pause();
      state.isShowPoster = true;
    }
    videoMuteControl();

    state.isShowVideo = event;
  };
  const handleClickAudioIcon = (event) => {
    if (state.isLoadingAudio === true) {
      return;
    }
    state.audioState = event;
    if (state.audioState === "dealer") {
      state.isShowAudio = true;
    } else {
      state.isShowAudio = false;
    }
    videoMuteControl();
  };
  const setHistoryHeight = () => {
    const container = document.querySelector(".container");
    const header = document.querySelector(".header");
    const cdn = document.querySelector(".live-cdn");
    const name = document.querySelector(".live-name");
    const price = document.querySelector(".live-price-div");
    const tab = document.querySelector(".live-tab");
    const height =
      container.clientHeight -
      (header.clientHeight +
        cdn.clientHeight +
        name.clientHeight +
        price.clientHeight +
        tab.clientHeight);
    const history = document.querySelector(".history-list");
    history.style.height = `${height}px`;
    state.ogHistiryHeight = height;
  };
  const setHistoryHideContentHeight = () => {
    const container = document.querySelector(".container");
    const header = document.querySelector(".header");
    const tab = document.querySelector(".live-tab");
    const height =
      container.clientHeight - (header.clientHeight + tab.clientHeight);
    const history = document.querySelector(".history-list");
    history.style.height = `${height}px`;
  };
  const handleClickHiddenContent = () => {
    state.isHiddenContent = !state.isHiddenContent;
    if (state.isHiddenContent) {
      setHistoryHideContentHeight();
    } else {
      const history = document.querySelector(".history-list");
      history.style.height = `${state.ogHistiryHeight}px`;
    }
  };
  const handleClickBetRed = () => {
    state.isRed = true;
    state.isBlue = false;
    state.betPrice = "0";
  };
  const handleClickBetBlue = () => {
    state.isRed = false;
    state.isBlue = true;
    state.betPrice = "0";
  };
  const handleClickTab = async (stateTab) => {
    state.isHistoryTab = stateTab;
    if (state.isHistoryTab === true && state.historyLists.length <= 0) {
      store.commit("priceModule/SET_ISLASTHISTORY_DATA", false);
    }
  };
  const addString = (string, number) => {
    var inputText;
    if (number === "remove") {
      string = "0";
    } else if (number === "delete") {
      inputText = string.slice(0, -1);
      if (inputText.length > 0) {
        string = inputText;
      } else {
        string = "0";
      }
    } else {
      if (string === "0") {
        string = "";
      }
      string += number;
    }
    return string;
  };
  const handleClickNumpad = (number) => {
    if (!state.isRed && !state.isBlue) {
      return;
    }
    state.betPrice = addString(state.betPrice, number);
    // console.log(+state.betPrice, state.gamePrice.home_price_limit);
    checkPriceSubmit();
  };
  const checkPriceSubmit = () => {
    if (state.isRed) {
      if (+state.betPrice < state.gamePrice.bet_min) {
        state.isSubmit = false;
        state.priceErr.status = "less";
        state.priceErr.bet = state.gamePrice.bet_min;
      } else if (+state.betPrice > state.gamePrice.home_price_limit) {
        state.isSubmit = false;
        state.priceErr.status = "more";
        state.priceErr.bet = state.gamePrice.home_price_limit;
      } else {
        state.isSubmit = true;
      }
    } else if (state.isBlue) {
      if (+state.betPrice < state.gamePrice.bet_min) {
        state.isSubmit = false;
        state.priceErr.status = "less";
        state.priceErr.bet = state.gamePrice.bet_min;
      } else if (+state.betPrice > state.gamePrice.away_price_limit) {
        state.isSubmit = false;
        state.priceErr.status = "more";
        state.priceErr.bet = state.gamePrice.away_price_limit;
      } else {
        state.isSubmit = true;
      }
    }
  };
  const plusString = (string, number) => {
    var result = parseInt(string) + parseInt(number);
    return result.toString();
  };
  const handleClickPlus = (number) => {
    if (!state.isRed && !state.isBlue) {
      return;
    }
    state.betPrice = plusString(state.betPrice, number);
    checkPriceSubmit();
  };
  const handleClickHistory = (isHistoryOpen) => {
    console.log("CLICLCIK");
    state.isHistoryOpen = isHistoryOpen;
  };
  const handleCloseNumpad = () => {
    state.isRed = false;
    state.isBlue = false;
    state.isSubmit = false;
    state.priceErr = {};
  };
  const handleClickSubmitPrice = async () => {
    if (state.isLoadingSubmit === true) {
      return;
    }
    if (!state.isSubmit) {
      var text = "";
      var bet;
      if (!state.priceErr.status) {
        text = `เงินเดิมพันไม่ถูกต้อง ขั้นต่ำ ${state.gamePrice.bet_min} บาท`;
        bet = state.gamePrice.bet_min;
      } else if (state.priceErr.status === "less") {
        text = `เงินเดิมพันไม่ถูกต้อง ขั้นต่ำ ${state.priceErr.bet} บาท`;
        bet = state.priceErr.bet;
      } else {
        text = `เงินเดิมพันไม่ถูกต้อง สูงสุดไม่เกิน ${state.priceErr.bet} บาท`;
        bet = state.priceErr.bet;
      }
      swalOption.html = `<span style='color:#fff'>${text}<span>`;
      swalAlt.fire(swalOption).then(async (isConfirm) => {
        if (isConfirm.isDismissed === true) {
          state.isSubmit = true;
          state.betPrice = bet;
        }
      });
      return;
    }
    state.isLoadingSubmit = true;
    var bet_team, price;

    if (state.isRed) {
      bet_team = "home";
      price = state.gamePrice.home_price;
    } else if (state.isBlue) {
      bet_team = "away";
      price = state.gamePrice.away_price;
    }
    const data = {
      game_price_id: parseInt(route.params.id),
      bet_team: bet_team,
      amount: parseInt(state.betPrice * 100),
      price: price,
    };
    if (isDemoMode()) {
      localStorage.setItem("bl", state.profile.balance);
      localStorage.setItem("hpr", state.gamePrice.home_price);
      localStorage.setItem("apr", state.gamePrice.away_price);
    }
    const response = await sendTicket(data);

    console.log(response);
    if (response.status) {
      if (isDemoMode()) {
        const newTicket = demoWss_newTicket(
          state.demoWssData["newTicket"],
          state.gamePrice,
          response.data
        );
        webSocketOnCmd(newTicket);
      }
      store.commit("priceModule/SET_BALANCE_DATA", response.data.balance / 100);
      response.data.amount = response.data.amount / 100;
      state.ticketLists[route.params.id].unshift(response.data);
      await store.dispatch("priceModule/getTicket", {
        id: route.params.id,
        data: state.ticketLists[route.params.id],
      });

      const index = state.historyLists.findIndex(
        (element) => element.id === +route.params.id
      );
      if (!state.historyLists[index] && !isDemoMode()) {
        store.commit("priceModule/SET_HISTORYLISTS_DATA", []);
        await store.dispatch("priceModule/getHistory", {
          lastHistory: state.isLastHistory,
        });
      }
      const btn = document.querySelector("#btnCloseDigitpad");
      btn.click();
      state.isLoadingSubmit = false;
      state.isBet = false;

      state.countTimeShow = state.countTime;
      state.countTimeIntv = setInterval(() => {
        state.countTimeShow = state.countTimeShow - 1;
        if (state.countTimeShow <= 0) {
          state.isBet = true;
          clearInterval(state.countTimeIntv);
        }
      }, 1000);
    } else {
      swalOption.html = `<span style='color:#fff'>${response.message}<span>`;
      swalAlt.fire(swalOption);
      const btn = document.querySelector("#btnCloseDigitpad");
      btn.click();
      state.isLoadingSubmit = false;
    }
  };
  const checkPriceType = (price) => {
    if (price.home_price === "9/10" && price.away_price === "9/10") {
      return "bar";
    } else if (
      (price.home_price === "8/10" && price.away_price === "10/10") ||
      (price.home_price === "10/10" && price.away_price === "8/10")
    ) {
      return "draw";
    } else {
      return "upper";
    }
  };
  const checkAndUpdateTicket = async () => {
    for (
      let index = 0;
      index < state.ticketLists[route.params.id].length;
      index++
    ) {
      if (state.ticketLists[route.params.id][index].status === 150) {
        await store.dispatch("priceModule/getTicket", {
          id: route.params.id,
        });
        return;
      }
    }
  };
  const checkCompleteGame = async (data) => {
    if (data.msg.game_price_id === +route.params.id) {
      await store.dispatch("priceModule/getTicket", {
        id: data.msg.game_price_id,
      });
    } else {
      if (state.isHistoryOpen === true) {
        await store.dispatch("priceModule/getTicket", {
          id: data.msg.game_price_id,
        });
      } else {
        store.commit("priceModule/SET_TICKETLISTS_DATA", {
          newTicketLists: {},
          id: data.msg.game_price_id,
          remove: true,
        });
      }
    }
  };
  const checkConnectWSS = () => {
    if (
      state.webSocket.connected !== undefined &&
      state.webSocket.connected === true
    ) {
      clearIntervalConnectWSS();
      swalAlt.close();
    } else {
      if (!swalAlt.isVisible()) {
        swalOption = {
          buttonsStyling: true,
          showCancelButton: false,
          showConfirmButton: false,
          html: `<span>กำลังเชื่อมต่อกับเซิร์ฟเวอร์<span></br>
          <span>กรุณารอสักครู่<span>
          <span class='dot dot1'>.</span>
          <span class='dot dot2'>.</span>
          <span class='dot dot3'>.</span>`,
          background: "rgba(0, 0, 0, 0.6)",
          width: "300px",
          allowOutsideClick: false,
          allowEscapeKey: false,
        };
        swalAlt.fire(swalOption);
      }
      if (state.wssConnectCount > 20) {
        dialogReconnect();
        clearIntervalConnectWSS();
      }
      state.wssConnectCount++;
    }
  };
  const dialogReconnect = () => {
    swalOption = {
      buttonsStyling: true,
      showCancelButton: false,
      showConfirmButton: false,
      html: `<div class='mb-3' style='color: #000'>
            <span class='position-relative'>
            <i class='fas fa-signal' style='font-size: 16px' ></i>
            <i class='fas fa-exclamation' style='font-size: 16px;position: absolute;right: -1px;bottom: 3px;'></i>
            </span>
            <span class='ps-1'>ไม่สามารถเชื่อมต่อได้</span>
            </div>
            <div>
            <button type="button" class="btn btn-warning mb-1" id="btn-reconnect"><i class="fas fa-redo pe-1"></i>ลองใหม่อีกครั้ง</button>
            </div>`,
      background: "#E9E9E9",
      allowOutsideClick: false,
      allowEscapeKey: false,
    };
    swalAlt.fire(swalOption);
    document
      .getElementById("btn-reconnect")
      .addEventListener("click", handleClickRefreshConnect);
  };
  const clearIntervalConnectWSS = () => {
    clearInterval(window.wssConnectIntv);
    state.wssConnectCount = 0;
  };
  const handleClickRefreshConnect = () => {
    router.go();
  };
  const initWebSocket = async () => {
    console.log("wss connect", state.webSocket.connected);
    store.commit("priceModule/SET_WEBSOCKET_DATA", {
      url: state.socketURL,
      streamerId: state.gamePrice.streamer_id,
      customer_id: state.profile.username,
    });
    setTimeout(() => {
      checkConnectWSS();
      window.wssConnectIntv = setInterval(() => {
        checkConnectWSS();
      }, 1000);
    }, 3000);
    // dialog connecting
    // client-side
    state.webSocket.on("connect", () => {
      console.log(`wss connected with the id of ${state.webSocket.id}`); // x8WIv7-mJelg7on_ALbx
      clearIntervalConnectWSS();
      swalAlt.close();
      state.wssAttempt = 0;
      // close dialog connecting
      if (state.needRefreshData === true) {
        state.webSocket.emit("cmd", {
          cmd: "getUpdateGamePrice",
          game_price_id: route.params.id,
        });
        checkAndUpdateTicket();
        state.needRefreshData = false;
      }
      checkNewGame();
    });
    state.webSocket.io.on("reconnect", () => {
      console.log("RECONNECTED");
      checkNewGame();
    });
    state.webSocket.io.on("reconnect_attempt", () => {
      clearIntervalConnectWSS();
      state.wssAttempt++;
      console.log("RECONNECT ATTEMPT", state.wssAttempt);
      if (state.wssAttempt >= 5) {
        state.webSocket.disconnect();
        dialogReconnect();
      }
    });
    // state.webSocket.io.on("reconnect_failed", () => {
    //   console.log("RECONNECT FAIL");
    //   clearIntervalConnectWSS();
    //   dialogReconnect();
    // });
    state.webSocket.on("disconnect", () => {
      console.log(`wss disconnected`); // undefined
      state.needRefreshData = true;
    });
    state.webSocket.on("msg", (data) => {
      console.log("msg", data);
    });
    // state.webSocket.on("reconnect", (data) => {
    //   console.log(data);
    //   /**
    //    * เพิ่ม function พวก refresh data ตรงนี้
    //    */
    // });
    // state.webSocket.on("cmd", async (data) => { }
    state.webSocket.on("cmd", webSocketOnCmd);
  };
  const webSocketOnCmd = async (data) => {
    console.log("game cmd top", data, Date.now());
    if (route.name !== "Game") {
      return;
    }
    if (data.msg === null) {
      return;
    }

    var id;
    if (data.msg.game_price_id !== undefined) {
      id = data.msg.game_price_id;
    } else {
      id = data.msg.id;
    }
    console.log("game_id", id, route.params.id);
    if (data.cmd === "updateGamePrice") {
      const index = state.historyLists.findIndex(
        (element) => element.id === data.msg.id
      );
      if (state.historyLists[index]) {
        state.historyLists[index].result = data.msg.result;
        store.commit("priceModule/SET_HISTORYLISTS_DATA", state.historyLists);
      }
    } else if (data.cmd === "completeGameTicket") {
      checkCompleteGame(data);
    } else if (data.cmd === "completeDrawTicket") {
      checkCompleteGame(data);
    } else if (data.cmd === "completeCancelTicket") {
      checkCompleteGame(data);
    } else if (data.cmd === "deposit ") {
      toast.success(`มีเงินฝากเข้ามา`, {
        position: "top-center",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
      store.commit("priceModule/SET_BALANCE_DATA", data.msg.balance / 100);
    } else if (data.cmd === "withdraw ") {
      toast.success(`มีเงินถอนออกไป`, {
        position: "top-center",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
      store.commit("priceModule/SET_BALANCE_DATA", data.msg.balance / 100);
    }
    if (id !== +route.params.id) {
      if (
        data.cmd === "setLive" &&
        data.msg.league_name === state.gameObj.league_name
      ) {
        if (state.gamePrice.status !== "live") {
          dialogNextGame(id, data.msg.home_name, data.msg.away_name);
        }
      }
    } else {
      if (data.cmd === "updateGamePrice") {
        if (state.isModalOpen && state.isLoadingSubmit === false) {
          if (data.msg.result !== state.gamePrice.result) {
            const closeBtn = document.querySelector(
              "#numpadModal #btnCloseDigitpad"
            );
            closeBtn.click();
            swalOption.html = `<span style='color:#fff'>ผลการแข่งขันมีการเปลี่ยนแปลง<span>`;
            swalAlt.fire(swalOption);
          } else if (data.msg.is_price_open !== true) {
            const closeBtn = document.querySelector(
              "#numpadModal #btnCloseDigitpad"
            );
            closeBtn.click();
            swalOption.html = `<span style='color:#fff'>มีการปิดราคาปัจจุบัน<span>`;
            swalAlt.fire(swalOption);
          } else if (
            data.msg.home_price !== state.gamePrice.home_price ||
            data.msg.away_price !== state.gamePrice.away_price
          ) {
            swalOption.html = `<span style='color:#fff'>ราคามีการเปลี่ยนแปลง <br/> 
                แดง:${checkPrice(
                  data.msg.home_price,
                  state.gamePrice.priceType,
                  false
                )} น้ำเงิน:${checkPrice(
              data.msg.away_price,
              state.gamePrice.priceType,
              false
            )} <span>`;
            swalAlt.fire(swalOption);
          }
        }
        data.msg.mode = state.gameObj.mode;
        await store.dispatch("priceModule/getGamePrice", {
          id: route.params.id,
          wss: true,
          data: data.msg,
        });
        state.countTime = state.gamePrice.ticket_delay_time;
        console.log(state.countTime);
        state.textPulse = true;
        setAutoApproveTime();
        // const index = state.historyLists.findIndex(
        //   (element) => element.id === data.msg.id
        // );
        // if (state.historyLists[index]) {
        //   state.historyLists[index].result = data.msg.result;
        //   store.commit("priceModule/SET_HISTORYLISTS_DATA", state.historyLists);
        // }
        console.log("pulse", state.textPulse);
        setTimeout(() => {
          state.textPulse = false;
          console.log("pulse", state.textPulse);
        }, 500);
      } else if (data.cmd === "setLive") {
        handleClickVideoIcon(true);
      } else if (data.cmd === "manangeBill" || data.cmd === "manageBill") {
        setTimeout(() => {
          checkAndUpdateTicket();
        }, Math.floor(Math.random() * 1501 + 500));
      } else if (data.cmd === "deleteGamePrice") {
        router.replace({
          name: "Match",
          params: { id: data.msg.streamer_id },
        });
      } else if (data.cmd === "newPinText") {
        store.commit("priceModule/SET_PIN_TEXT_DATA", data.msg);
      } else if (data.cmd === "updatePool") {
        store.commit("priceModule/SET_GAMEPRICE_TOTAL_POOL_DATA", data.msg);
      } else if (data.cmd === "updateLastPool") {
        updateLastPool(data.msg);
      } else if (data.cmd === "newTicket") {
        data.msg.mode = state.gameObj.mode;
        console.log("ticket", data.msg);
        store.commit("priceModule/SET_GAMEPRICE_TOTAL_DATA", data.msg);
        if (state.gameObj.mode === "pool") {
          return;
        }
        state.ticketArr.push(data.msg);
        state.timeoutArr[state.ticketArr.length - 1] = setTimeout(() => {
          const chat = document.querySelector(`#chat0`);
          if (chat === null) {
            return;
          }
          chat.classList.add("fade-out-text");
          setTimeout(() => {
            chat.classList.remove("fade-out-text");
            state.ticketArr.shift();
          }, 500);
        }, 5000);
        if (state.ticketArr.length > 3) {
          clearTimeout(state.timeoutArr[0]);
          state.timeoutArr.shift();
          const chat = document.querySelector(`#chat0`);
          if (chat === null) {
            return;
          }
          chat.classList.add("fade-out-text");
          setTimeout(() => {
            chat.classList.remove("fade-out-text");
            state.ticketArr.shift();
          }, 500);
        }
      }
    }
  };
  const dialogNextGame = async (id, home_name, away_name) => {
    if (isNoNameGame() === false) {
      swalOption2.html = `<div class="top">
              <div class="mb-2">คู่ถัดไปกำลังถ่ายทอดสด</div>
              <div>กดดูคู่ต่อไปเพื่อรับชม</div>
            </div>
            <div class="center mt-4 p-3">
              <div>
                ${home_name}
              </div>
              <div class="my-2">
                VS
              </div>
              <div>
                ${away_name}
              </div>
            </div>`;
      swalAlt.fire(swalOption2).then(async (isConfirm) => {
        if (isConfirm.isConfirmed) {
          console.log("confirm");
          await router.replace({
            name: "Game",
            params: { id: id },
            query: { f: "1" },
          });
          console.log("new game", route.params.id);
          initGameData(false);
          // router.go();
        }
      });
    } else {
      await router.replace({
        name: "Game",
        params: { id: id },
        query: { f: "1" },
      });
      initGameData(false);
    }
  };
  const checkNewGame = async () => {
    const response = await getMatch(state.gameObj.streamer_id);
    if (response.status === true) {
      const foundObject = response.data.find(
        (obj) => obj.league_id === state.gameObj.league_id
      );
      console.log("foundObject", foundObject);
      if (foundObject) {
        for (let index = 0; index < foundObject.game_list.length; index++) {
          const element = foundObject.game_list[index];
          if (element.id !== state.gamePrice.id) {
            if (element.status === "live") {
              dialogNextGame(element.id, element.home_name, element.away_name);
            }
            break;
          }
        }
      }
    }
  };
  const demoWss = () => {
    if (state.demoWssData["init"] !== true) {
      state.demoWssData["init"] = true;
      state.demoWssData["updateGamePrice"] = {};
      state.demoWssData["newTicket"] = {};
    }
    const updateGamePrice = demoWss_updateGamePrice(
      state.demoWssData["updateGamePrice"]
    );
    const newTicket = demoWss_newTicket(
      state.demoWssData["newTicket"],
      state.gamePrice
    );
    if (updateGamePrice !== null) {
      webSocketOnCmd(updateGamePrice);
    }
    if (newTicket !== null) {
      webSocketOnCmd(newTicket);
    }
  };
  return {
    state,
    handleAudioLoading,
    handleClickChatIcon,
    handleClickVolumeIcon,
    handleClickVideoIcon,
    handleClickAudioIcon,
    handleClickHiddenContent,
    handleClickTab,
    handleClickBetRed,
    handleClickBetBlue,
    handleClickNumpad,
    handleClickPlus,
    handleCloseNumpad,
    handleClickHistory,
    handleClickSubmitPrice,
    checkPriceType,
    handleClickInfo,
    isNoNameGame,
    setHistoryHeight,
    handleClickVideoFullScreen,
    handleHoverVideo,
    voiceActorMode,
  };
}
