<template>
  <div v-if="transaction" class="transaction-item mb-3">
    <div class="header">
      <span># {{ transaction.id }}</span>
    </div>
    <div class="body">
      <div class="text-start">
        <div class="type">
          {{ transaction.type === 100 ? "ฝากเงิน" : "ถอนเงิน" }}
        </div>
        <div class="time">
          {{
            ("0" + transaction.fullDate.getDate()).slice(-2) +
            " " +
            shortMonths[transaction.fullDate.getMonth()] +
            " " +
            (transaction.fullDate.getFullYear() + 543) +
            " " +
            ("0" + transaction.fullDate.getHours()).slice(-2) +
            ":" +
            ("0" + transaction.fullDate.getMinutes()).slice(-2) +
            " น."
          }}
        </div>
      </div>
      <div class="text-end">
        <div
          class="money"
          :class="
            transaction.status === 100
              ? 'pending'
              : transaction.type === 100
              ? 'success'
              : transaction.type === 200
              ? 'withdraw'
              : ''
          "
        >
          {{
            `${transaction.type === 100 ? "+" : "-"}${numberWithCommas(
              transaction.amount / 100
            )}`
          }}<span class="currency">฿</span>
        </div>
        <div class="status">
          {{
            transaction.status === 100
              ? "รอตรวจสอบ"
              : transaction.status === 400
              ? "สำเร็จแล้ว"
              : "ยกเลิก"
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { utilily } from "@/utilities/utility";

export default {
  name: "Transaction-item",
  props: ["transaction"],
  setup() {
    const { numberWithCommas } = utilily();
    const state = reactive({
      shortMonths: [
        "ม.ค.",
        "ก.พ.",
        "มี.ค.",
        "เม.ย.",
        "พ.ค.",
        "มิ.ย.",
        "ก.ค.",
        "ส.ค.",
        "ก.ย.",
        "ต.ค.",
        "พ.ย.",
        "ธ.ค.",
      ],
    });

    return {
      ...toRefs(state),
      numberWithCommas,
    };
  },
};
</script>

<style lang="scss" scoped></style>
