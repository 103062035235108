<template>
  <div v-if="isSidebar" class="bg-grey sidebar">
    <div class="row align-items-center bg-sidebar mx-0">
      <div
        @click="handleClickHome"
        class="col-5 offset-3 offset-md-2 px-0 pt-3"
      >
        <img
          class="res16-9-2 cursor-pointer"
          src="../assets/images/Logo-White.png"
          alt=""
        />
      </div>
      <div
        @click="handleClickCloseSidebar"
        class="position-absolute top-0 end-0 text-close cursor-pointer"
      >
        <i class="fas fa-times"></i>
      </div>
    </div>
    <div>
      <div class="sidebar-tab text-start">
        <span class="ps-3 fs-1-5"><i class="fas fa-user"></i></span>&emsp;
        <span>{{ profile.username }}</span>
      </div>
      <div @click="handleClickHome" class="sidebar-tab text-start">
        <span class="ps-3 fs-1-5" :class="isStreamer === null ? 'disabled' : ''"
          ><i class="fas fa-home"></i></span
        >&emsp;
        <span :class="isStreamer === null ? 'disabled' : ''"
          >กลับสู่หน้าหลัก</span
        >
      </div>
      <div @click="handleClickTransaction" class="sidebar-tab text-start">
        <span class="ps-3 fs-1-5"><i class="fas fa-coins"></i></span>&emsp;
        <span>รายงานการเงิน</span>
      </div>
      <div @click="handleClickHistory" class="sidebar-tab text-start">
        <span class="ps-3 fs-1-5" :class="isStreamer === null ? 'disabled' : ''"
          ><i class="fas fa-history"></i></span
        >&emsp;
        <span :class="isStreamer === null ? 'disabled' : ''"
          >ประวัติการแทง</span
        >
      </div>
      <!-- <div class="sidebar-tab text-start">
        <span class="ps-3 fs-1-5"><i class="fas fa-comments"></i></span>&emsp;
        <span>ติดต่อเรา</span>
      </div> -->
    </div>
    <div class="sidebar-btn">
      <button @click="handleClickStreamer" class="btn btn-dark">
        กลับสู่หน้าหลัก
      </button>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "Sidebar",
  setup() {
    const store = useStore();
    const router = useRouter();
    const isSidebar = computed(() => store.state.defaultModule.isSidebar);
    const isStreamer = computed(() => store.state.defaultModule.isStreamer);
    const profile = computed(() => store.state.priceModule.profile);
    const handleClickCloseSidebar = () => {
      store.commit("defaultModule/SET_IsSidebar", false);
    };
    const handleClickHome = () => {
      if (isStreamer.value === null) {
        return;
      }
      // router.replace({ name: "Match", params: { id: isStreamer.value } });
      router.replace({ name: "Streamer" });
      store.commit("defaultModule/SET_IsSidebar", false);
    };
    const handleClickTransaction = () => {
      router.replace({ name: "Transaction" });
      store.commit("defaultModule/SET_IsSidebar", false);
    };
    const handleClickHistory = () => {
      if (isStreamer.value === null) {
        return;
      }
      router.replace({ name: "History" });
      store.commit("defaultModule/SET_IsSidebar", false);
    };
    const handleClickStreamer = async () => {
      router.replace({ name: "Streamer" });
      store.commit("defaultModule/SET_IsSidebar", false);
    };
    return {
      isSidebar,
      isStreamer,
      profile,
      handleClickCloseSidebar,
      handleClickHome,
      handleClickTransaction,
      handleClickHistory,
      handleClickStreamer,
    };
  },
};
</script>

<style lang="scss"></style>
