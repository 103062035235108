<template>
  <div>
    <div class="transaction-balance pt-3 pb-4">
      <div class="balance-div">
        <span class="balance">{{ numberWithCommas(profile.balance) }} </span>
        <span class="currency">฿</span>
        &nbsp;
        <span @click="handleClickRefresh" class="refresh"
          ><i class="fas fa-sync-alt"></i
        ></span>
      </div>
      <div>
        <span class="type">กระเป๋าเงิน / THB</span>
      </div>
    </div>
    <div class="row mx-0">
      <div
        @click="handleClickTab(true)"
        class="col-6 transaction-tab py-2"
        :class="isLastTransaction ? 'active' : ''"
      >
        รายการล่าสุด
      </div>
      <div
        @click="handleClickTab(false)"
        class="col-6 transaction-tab py-2"
        :class="!isLastTransaction ? 'active' : ''"
      >
        รายการย้อนหลัง
      </div>
      <div
        v-show="isLastTransaction === true"
        id="tabLast"
        class="col-12 pt-3 transaction-list"
      >
        <div v-if="transaction.length <= 0" class="my-3">ไม่มีรายการ</div>
        <div v-else>
          <Transactionitem
            v-for="index in 10"
            :key="index"
            :transaction="transaction[index - 1]"
          />
        </div>
      </div>
      <div
        v-show="isLastTransaction === false"
        id="tabHistory"
        class="col-12 transaction-list"
      >
        <div class="row transaction-selecter pt-3">
          <div class="col-7 pe-1">
            <Datepicker
              v-model="date"
              range
              :format="format"
              :clearable="false"
              :enableTimePicker="false"
              position="left"
            ></Datepicker>
          </div>
          <div class="col-5 ps-0 text-end dropdown">
            <button
              class="btn dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              :class="
                selectTransaction === 0
                  ? 'yellow-btn'
                  : selectTransaction === 100
                  ? 'green-btn'
                  : selectTransaction === 200
                  ? 'red-btn'
                  : ''
              "
            >
              {{
                selectTransaction === 0
                  ? "รายการทั้งหมด"
                  : selectTransaction === 100
                  ? "รายการฝาก"
                  : selectTransaction === 200
                  ? "รายการถอน"
                  : ""
              }}
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li v-show="selectTransaction !== 0" class="white-btn">
                <a @click="handleClickSelect(0)" class="dropdown-item yellow"
                  >รายการทั้งหมด</a
                >
              </li>
              <li v-show="selectTransaction !== 100" class="white-btn">
                <a @click="handleClickSelect(100)" class="dropdown-item green"
                  >รายการฝาก</a
                >
              </li>
              <li v-show="selectTransaction !== 200" class="white-btn">
                <a @click="handleClickSelect(200)" class="dropdown-item red"
                  >รายการถอน</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div v-if="selectDate.length <= 0" class="my-3">ไม่มีรายการ</div>
        <div v-else>
          <div v-for="(select, index) in selectDate" :key="index">
            <div v-if="select.firstLine === true" class="transaction-group">
              <div class="text-start">
                {{
                  ("0" + select.fullDate.getDate()).slice(-2) +
                  " " +
                  fullMonths[select.fullDate.getMonth()] +
                  " " +
                  (select.fullDate.getFullYear() + 543) +
                  " "
                }}
              </div>
              <div class="text-end">{{ select.list }} รายการ</div>
            </div>
            <Transactionitem
              v-show="
                selectTransaction === 0 || selectTransaction === select.type
              "
              :transaction="select"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs } from "vue";
import { useTransaction } from "./composable/use-transaction";
import Transactionitem from "@/components/Transaction.vue";
import { utilily } from "@/utilities/utility";
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";

export default {
  name: "Transaction",
  components: { Datepicker, Transactionitem },
  setup() {
    const {
      state,
      handleClickRefresh,
      handleClickTab,
      handleClickSelect,
      handleUpdateDatetime,
    } = useTransaction();
    const { numberWithCommas } = utilily();
    return {
      ...toRefs(state),
      handleClickRefresh,
      handleClickTab,
      handleClickSelect,
      handleUpdateDatetime,
      numberWithCommas,
    };
  },
};
</script>

<style lang="scss" scoped></style>
