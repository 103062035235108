<template>
  <div v-if="historyLists.length <= 0" class="no-ticket mt-4">
    ยังไม่มีรายการ
  </div>
  <div v-else class="accordion" id="accordionHistory">
    <div
      v-for="(historyList, i) in historyLists"
      :key="i"
      class="accordion-item py-1"
    >
      <h2 class="accordion-header" :id="`heading${i}`">
        <button
          @click="handleClickHistory(historyList.id)"
          class="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          :data-bs-target="`#collapse${i}`"
          aria-expanded="true"
          :aria-controls="`collapse${i}`"
        >
          <div class="match-number game active">
            <div class="match-number-active py-1">#{{ historyList.id }}</div>
            <div
              class="match-number-active game py-1"
              :class="[
                historyList.result === 'home' ? 'text-white bg-red' : '',
                historyList.result === 'away' ? 'text-white bg-blue' : '',
              ]"
            >
              <div class="w-100 text-center">
                <span v-if="historyList.result === 'home'">แดงชนะ</span>
                <span v-else-if="historyList.result === 'away'">
                  น้ำเงินชนะ
                </span>
                <span v-else-if="historyList.result === 'draw'">เสมอ</span>
                <span v-else-if="historyList.result === 'cancel'">ยกเลิก</span>
                <span v-else>รอผล</span>
              </div>
            </div>
          </div>
          <div class="match-name py-2">
            <div class="position-relative pb-2">
              <div class="color-box bg-red"></div>
              <div class="text-truncate">{{ historyList.home_name }}</div>
            </div>
            <div class="position-relative">
              <div class="color-box bg-blue"></div>
              <div class="text-truncate">{{ historyList.away_name }}</div>
            </div>
          </div>
        </button>
      </h2>
      <div
        :id="`collapse${i}`"
        class="accordion-collapse collapse"
        :aria-labelledby="`heading${i}`"
        data-bs-parent="#accordionHistory"
      >
        <div
          v-if="!ticketLists[historyList.id]"
          class="accordion-body position-relative game"
        >
          <div class="loading-history">
            <div class="spinner-border loading-spinner mb-2" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div class="row border-history-top pt-2 mx-2">
            <div class="col-6 pb-2 text-start">ยอดเดิมพัน</div>
            <div class="col-6 pb-2 text-end">0</div>
            <div class="col-6 pb-2 text-start text-white">ผลได้เสีย</div>
            <div class="col-6 pb-2 text-end text-white">0</div>
          </div>
        </div>
        <div v-else class="accordion-body game">
          <div
            v-for="(ticketList, j) in ticketLists[historyList.id]"
            :key="j"
            class="row pb-2 mx-2"
            :class="
              ticketList.isNewRound && j !== 0 ? 'border-history-top pt-2' : ''
            "
          >
            <div class="col-12 px-0 pb-1 text-center">
              {{ ticketList.isNewRound ? ticketList.round_text : "" }}
            </div>
            <div class="col-6 px-0 no-warp">
              <span
                class="badge"
                :class="
                  ticketList.bet_team === 'home' ? 'bg-red' : 'bg-secondary'
                "
                >{{
                  ticketList.bet_team === "home"
                    ? checkPrice(
                        ticketList.home_price,
                        ticketList.priceType,
                        true
                      )
                    : checkPrice(ticketList.home_price, "No", true)
                }}</span
              >
              &nbsp;
              <span
                class="badge"
                :class="
                  ticketList.bet_team === 'away' ? 'bg-blue' : 'bg-secondary'
                "
                >{{
                  ticketList.bet_team === "away"
                    ? checkPrice(
                        ticketList.away_price,
                        ticketList.priceType,
                        true
                      )
                    : checkPrice(ticketList.away_price, "No", true)
                }}</span
              >
            </div>
            <div class="col-3 px-0 text-end">
              {{ numberWithCommas(ticketList.amount) }}
            </div>
            <div
              class="col-3 px-0 text-end"
              :class="
                ticketList.status === 300 ||
                ticketList.status === 650 ||
                ticketList.status === 690 ||
                ticketList.status === 890
                  ? 'text-red'
                  : ticketList.status === 600
                  ? 'text-green'
                  : ''
              "
            >
              {{
                ticketList.status === 600
                  ? `+${numberWithCommas(
                      ticketList.reward - ticketList.amount
                    )}`
                  : ticketList.status === 650
                  ? `-${numberWithCommas(ticketList.amount)}`
                  : ticketList.status === 670 || ticketList.status === 680
                  ? 0
                  : ticketList.status === 300 || ticketList.status === 890
                  ? "ยกเลิก"
                  : ticketList.status === 150
                  ? "-"
                  : numberWithCommas(ticketList.reward - ticketList.amount)
              }}
            </div>
          </div>
          <div class="row border-history-top pt-2 mx-2">
            <div class="col-6 pe-0 pb-2 text-start">ยอดเดิมพัน</div>
            <div class="col-6 px-0 pb-2 text-end">
              {{ numberWithCommas(ticketLists[historyList.id].totalBet) }}
            </div>
            <div
              class="col-6 pe-0 pb-2 text-start"
              :class="
                ticketLists[historyList.id].result > 0
                  ? 'text-green'
                  : ticketLists[historyList.id].result < 0
                  ? 'text-red'
                  : 'text-white'
              "
            >
              ผลได้เสีย
            </div>
            <div
              class="col-6 px-0 pb-2 text-end"
              :class="
                ticketLists[historyList.id].result > 0
                  ? 'text-green'
                  : ticketLists[historyList.id].result < 0
                  ? 'text-red'
                  : 'text-white'
              "
            >
              {{ numberWithCommas(ticketLists[historyList.id].result) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { utilily } from "@/utilities/utility";
import { useHistory } from "./composable/use-history";
import { toRefs } from "@vue/reactivity";
export default {
  name: "History",
  props: ["gamePrice", "commission"],
  setup() {
    const { state, handleClickHistory } = useHistory();
    const { numberWithCommas, abbrNum, checkPrice } = utilily();
    return {
      ...toRefs(state),
      handleClickHistory,
      numberWithCommas,
      abbrNum,
      checkPrice,
    };
  },
};
</script>

<style lang="scss" scoped></style>
