<template>
  <div class="container position-relative">
    <div class="loading">
      ไม่สามารถเข้าใช้งานได้ <br />
      ขณะนี้อยู่ในขณะปรับปรุงระบบ
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";

export default {
  name: "Maintenance",
  setup() {
    const state = reactive({
      count: 0,
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss" scoped></style>
