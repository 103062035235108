const axios = require("axios");
export function utilily() {
  const calculateAspectRatio = (widthOrHeight, isWidth = true) => {
    const aspectRatio = 16 / 9;
    return isWidth
      ? { width: widthOrHeight, height: widthOrHeight / aspectRatio }
      : { width: widthOrHeight * aspectRatio, height: widthOrHeight };
  };
  const getSocketURL = async () => {
    let result = await axios.get(
      process.env.VUE_APP_BALANCER_URL
        ? process.env.VUE_APP_BALANCER_URL
        : "https://wss-bal.muaysod.com/fetch/"
    );
    var socketURL;
    console.log(result.data.data.url);
    if (result.data.data.url) socketURL = result.data.data.url;
    else {
      console.log("skip fetching url.");
      socketURL = "wss-p01.muaysod.com";
    }
    console.log("socket URL", socketURL);
    return socketURL;
  };
  const setHeightElement = (elementArr) => {
    var height = 0;
    for (let index = 0; index < elementArr.length; index++) {
      const element = document.querySelector(elementArr[index]);
      if (index === 0) {
        height = height + element.clientHeight;
      } else {
        height = height - element.clientHeight;
      }
    }
    return height;
  };
  const checkHistory = (historyLists, isLastHistory, streamer_id) => {
    let data;
    if (historyLists[historyLists.length - 1]) {
      data = {
        lastHistory: isLastHistory,
        id: historyLists[historyLists.length - 1].id,
        streamer_id: parseInt(streamer_id),
      };
    } else {
      data = {
        lastHistory: isLastHistory,
        streamer_id: parseInt(streamer_id),
      };
    }
    return data;
  };
  const isMobile = () => {
    var check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };
  const numberWithCommas = (number = null, decimal = 2, pool = false) => {
    if (!number) {
      return 0;
    }
    var fixedNumber;
    if (pool === true) {
      if ((number.toString().split(".")[1] || []).length !== 2) {
        fixedNumber = (Math.floor(number * 100) / 100).toFixed(decimal);
      } else {
        fixedNumber = number;
      }

      // fixedNumber = number.toFixed(decimal);
      return String(fixedNumber);
    } else {
      fixedNumber = parseFloat(
        parseFloat(number).toFixed(decimal)
      ).toLocaleString("en-IN", {
        useGrouping: true,
      });
      return String(fixedNumber.replace(/,/g, "")).replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );
    }
  };
  const abbrNum = (number, decPlaces) => {
    decPlaces = Math.pow(10, decPlaces);
    var abbrev = ["k", "m", "b", "t"];

    for (var i = abbrev.length - 1; i >= 0; i--) {
      var size = Math.pow(10, (i + 1) * 3);
      if (size <= number) {
        number = Math.round((number * decPlaces) / size) / decPlaces;
        if (number == 1000 && i < abbrev.length - 1) {
          number = 1;
          i++;
        }
        number += abbrev[i];
        break;
      }
    }
    return number;
  };
  const reverseString = (string) => {
    return string.split("/").reverse().join("/");
  };
  const checkPrice = (string, type, history = false) => {
    if (!string) {
      return;
    }
    // console.log(state.isSelectPriceType);
    const Arr = string.split("/");
    var text1 = "";
    var text2 = "";
    // POOL
    let pattern = /\b1\/1\.0\b/;
    let match = string.match(pattern);
    if (match) {
      return "รอปิดราคา";
    } else if (string.indexOf("/1.0") >= 0) {
      return numberWithCommas(Arr[0], 2, true);
    }
    // NORMAL
    if (history) {
      text1 = "ต";
      text2 = "ร";
    } else {
      text1 = "ต่อ";
      text2 = "รอง";
    }
    if (type !== "upper") {
      if (Arr[1] / Arr[0] < 1) {
        return string;
      } else {
        return reverseString(string);
      }
    }
    if (Arr[1] / Arr[0] <= 1) {
      // ต่อ
      return `${text2} ${string}`;
    } else {
      // รอง
      return `${text1} ${reverseString(string)}`;
    }
  };
  const findTax = (percentageReward, commission_table) => {
    var tax = 0;
    if (commission_table !== null) {
      for (let index = commission_table.length; index > 0; index--) {
        const element = commission_table[index - 1];
        if (percentageReward >= element.persent_reward) {
          tax = element.persent_tax;
          return tax;
        }
      }
      tax = commission_table[0].persent_tax;
    }
    return tax;
  };
  const calculatePercentageReward = (a, b, commission_table) => {
    var percentageReward = 0;
    var commissionReward = 0;
    var customerReward = 0;
    var percentageTax = 0;

    percentageReward = (b / a) * 100;
    percentageTax = findTax(percentageReward, commission_table);
    commissionReward = (b / 100) * percentageTax;
    customerReward = (b - commissionReward) / a + 1;
    // customerReward = ((b - commissionReward) / a) * 100 + 100;

    return customerReward;
  };
  const isEmpty = (obj) => {
    for (var key in obj) {
      if (obj[key] !== undefined) {
        return false;
      }
    }
    return true;
  };
  return {
    getSocketURL,
    numberWithCommas,
    abbrNum,
    checkPrice,
    checkHistory,
    isMobile,
    setHeightElement,
    calculatePercentageReward,
    calculateAspectRatio,
    isEmpty,
  };
}
