<template>
  <div
    v-show="!isHiddenContent"
    class="row py-2 bg-32 live-price-div position-relative"
    :class="
      gamePrice.is_price_open === false || gamePrice.is_pause === true
        ? 'disabled'
        : ''
    "
  >
    <div
      v-if="
        approveTime >= 0 &&
        gameObj.auto_approve_time > 0 &&
        gameObj.auto_approve_time !== null &&
        gamePrice.is_price_open === true
      "
      class="close-time py-2"
    >
      <div><i class="fas fa-stopwatch"></i> {{ approveTime }}</div>
    </div>
    <div
      v-if="gamePrice.result !== 'wait' && gamePrice.result"
      class="col mx-2 result text-center"
      :class="gamePrice.result !== 'wait' ? gamePrice.result : ''"
    >
      {{
        gamePrice.result === "home"
          ? "แดงชนะ"
          : gamePrice.result === "away"
          ? "น้ำเงินชนะ"
          : gamePrice.result === "draw"
          ? "เสมอ"
          : gamePrice.result === "cancel"
          ? "ยกเลิก"
          : ""
      }}
    </div>
    <div
      v-if="gamePrice.result === 'wait' || isLoading"
      class="col live-price red ms-3 me-2"
      :class="gamePrice.home_price_limit <= 0 ? 'disabled' : ''"
    >
      <div class="pb-2" :class="textPulse ? 'pulse-text' : ''">
        <span
          class="top"
          :class="[
            gamePrice.is_price_open === false || gamePrice.is_pause === true
              ? 'pb-2'
              : '',
          ]"
        >
          {{
            gamePrice.is_pause === false &&
            gamePrice.is_price_open === true &&
            gamePrice.home_price_limit > 0
              ? checkPrice(gamePrice.home_price, gamePrice.priceType)
              : "-"
          }}
        </span>
        <br />
        <span
          v-if="
            gamePrice.is_price_open === true && gamePrice.is_pause === false
          "
          class="middle pb-2"
        >
          {{
            gamePrice.mode === "normal"
              ? gamePrice.home_price_limit > 0
                ? numberWithCommas(gamePrice.total_home_amount / 100, 0) + "฿"
                : "- ฿"
              : "( " +
                numberWithCommas(
                  gamePrice.home_price.split("/")[0] /
                    gamePrice.home_price.split("/")[1] +
                    1,
                  2,
                  true
                ) +
                " )"
          }}
        </span>
      </div>
      <div class="bottom pb-2">
        <button
          @click="$emit('handleClickBetRed')"
          class="btn btn-white text-red"
          :class="gamePrice.is_price_open === false ? 'close' : ''"
          data-bs-dismiss="modal"
          :data-bs-toggle="
            gamePrice.is_price_open === true && gamePrice.is_pause === false
              ? 'modal'
              : ''
          "
          data-bs-target="#numpadModal"
          :disabled="gamePrice.home_price_limit <= 0 ? true : false"
        >
          แทงฝั่งแดง
        </button>
      </div>
    </div>
    <div
      v-if="gamePrice.result === 'wait' || isLoading"
      class="col live-price blue ms-2 me-3"
      :class="gamePrice.away_price_limit <= 0 ? 'disabled' : ''"
    >
      <div class="pb-2" :class="textPulse ? 'pulse-text' : ''">
        <span
          class="top"
          :class="[
            gamePrice.is_price_open === false || gamePrice.is_pause === true
              ? 'pb-2'
              : '',
          ]"
        >
          {{
            gamePrice.is_pause === false &&
            gamePrice.is_price_open === true &&
            !gamePrice.is_pause &&
            gamePrice.away_price_limit > 0
              ? checkPrice(gamePrice.away_price, gamePrice.priceType)
              : "-"
          }}
        </span>
        <br />
        <span
          v-if="
            gamePrice.is_price_open === true && gamePrice.is_pause === false
          "
          class="middle pb-2"
        >
          {{
            gamePrice.mode === "normal"
              ? gamePrice.away_price_limit > 0
                ? numberWithCommas(gamePrice.total_away_amount / 100, 0) + "฿"
                : "- ฿"
              : "( " +
                numberWithCommas(
                  gamePrice.away_price.split("/")[0] /
                    gamePrice.away_price.split("/")[1] +
                    1,
                  2,
                  true
                ) +
                " )"
          }}
        </span>
      </div>
      <div class="bottom pb-2">
        <button
          @click="$emit('handleClickBetBlue')"
          class="btn btn-white text-blue"
          :class="gamePrice.is_price_open === false ? 'close' : ''"
          data-bs-dismiss="modal"
          :data-bs-toggle="
            gamePrice.is_price_open === true && gamePrice.is_pause === false
              ? 'modal'
              : ''
          "
          data-bs-target="#numpadModal"
          :disabled="gamePrice.away_price_limit <= 0 ? true : false"
        >
          แทงฝั่งน้ำเงิน
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { utilily } from "@/utilities/utility";

export default {
  props: [
    "gamePrice",
    "isHiddenContent",
    "approveTime",
    "gameObj",
    "isLoading",
    "textPulse",
  ],
  setup() {
    const { numberWithCommas, checkPrice } = utilily();
    const state = reactive({
      count: 0,
    });

    return {
      ...toRefs(state),
      checkPrice,
      numberWithCommas,
    };
  },
};
</script>

<style lang="scss" scoped></style>
