<template>
  <div
    class="row header justify-content-center align-items-center lh-lg shadow"
  >
    <div
      v-if="iconLeft === 'sort'"
      @click="handleClickOpenSidebar"
      class="col-5 text-start pe-0 header-angle"
    >
      <div class="cursor-pointer w-fit"><i class="fas fa-bars"></i></div>
    </div>
    <div v-else class="col-5 text-start pe-0 header-angle">
      <router-link @click.prevent="handleClickBack" :to="{}">
        <div class="cursor-pointer w-fit">
          <i class="fas fa-angle-left cursor-pointer"></i>
        </div>
      </router-link>
    </div>
    <div @click="handleClickHome" class="col-2 px-0 cursor-pointer">
      <img
        class="res16-9-2"
        src="../assets/images/Logo-White-Header.png"
        alt=""
      />
    </div>
    <div class="col-5 ps-0 header-text">
      <span @click="handleClickRefresh" class="badge bg-secondary cp"
        ><i class="fas fa-coins"></i>
        {{ `${numberWithCommas(profile.balance)} ฿` }}&nbsp;
        <i class="fas fa-sync-alt"></i>
      </span>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed, onMounted } from "@vue/runtime-core";
import { utilily } from "@/utilities/utility";
export default {
  name: "Header",
  props: ["iconLeft"],
  setup() {
    const store = useStore();
    const router = useRouter();
    const profile = computed(() => store.state.priceModule.profile);
    const gamePrice = computed(() => store.state.priceModule.gamePrice);
    const { numberWithCommas, abbrNum } = utilily();
    onMounted(async () => {
      await store.dispatch("priceModule/getProfile");
    });
    const handleClickRefresh = async () => {
      await store.dispatch("priceModule/getProfile");
    };
    const handleClickBack = () => {
      console.log(gamePrice.value.streamer_id);
      router.replace({
        name: "Match",
        params: { id: gamePrice.value.streamer_id },
      });
    };
    const handleClickOpenSidebar = () => {
      store.commit("defaultModule/SET_IsSidebar", true);
    };
    const handleClickHome = () => {
      router.replace({ name: "Streamer" });
    };
    return {
      profile,
      gamePrice,
      handleClickRefresh,
      handleClickBack,
      handleClickOpenSidebar,
      handleClickHome,
      numberWithCommas,
      abbrNum,
    };
  },
};
</script>

<style lang="scss"></style>
