<template>
  <div v-if="isLoading === true" class="no-match">กรุณารอสักครู่</div>
  <div v-else-if="matchlists.length <= 0" class="no-match">ยังไม่มีรายการ</div>
  <div v-else-if="matchlists.length > 0" class="accordion" id="accordionMatch">
    <div
      v-for="(matchlist, i) in matchlists"
      :key="i"
      class="accordion-item py-2"
    >
      <h2 class="accordion-header" :id="`heading${i}`">
        <button
          class="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          :data-bs-target="`#collapse${i}`"
          aria-expanded="true"
          :aria-controls="`collapse${i}`"
          :class="matchlist.show === false ? 'collapsed' : ''"
        >
          <img
            v-if="matchlist.league_image"
            class="accordion-header-img"
            :src="matchlist.league_image"
            alt=""
          />
          <span>
            {{ matchlist.name }}&emsp;{{
              matchlist.isClose === false && matchlist.game_list.length > 0
                ? matchlist.fullDate
                : ""
            }}
          </span>
        </button>
        <div
          v-if="matchlist.is_live === true && matchlist.isClose === false"
          class="live-icon"
          style="top: -5px !important"
        >
          <LiveIcon v-if="isMobile() === false" :id="'lavContainer' + i" />
          <div v-else class="badge badge-live">
            <div class="m-auto">
              <!-- <div class="live-animated"><i class="fas fa-signal"></i></div> -->
              <span>LIVE</span>
            </div>
          </div>
        </div>
      </h2>
      <div
        :id="`collapse${i}`"
        class="accordion-collapse"
        :class="
          matchlist.show === true ||
          matchlist.isClose === true ||
          matchlist.game_list.length <= 0
            ? 'collapse show'
            : 'collapse'
        "
        :aria-labelledby="`heading${i}`"
        data-bs-parent="#accordionMatch"
      >
        <!-- background-image: url(https://muaysod-dev.ap-south-1.linodeobjects.com/191/Fifa2.gif); -->
        <div
          v-if="matchlist.isClose === true || matchlist.game_list.length <= 0"
          class="isclose-div"
          :style="`background-image: url(${matchlist.offline_banner});`"
        >
          <div
            class="d-flex justify-content-center align-items-center center-content"
          >
            {{
              matchlist.isCloseMsg !== "" ? matchlist.isCloseMsg : "Coming Soon"
            }}
          </div>
        </div>
        <div
          v-else-if="
            matchlist.isClose === false && matchlist.game_list.length > 0
          "
          class="accordion-body"
        >
          <div v-if="matchlist.can_new_game === false" class="match-time">
            <div class="badge badge-time">
              {{ matchlist.time_start }} - {{ matchlist.time_end }} น.
            </div>
          </div>
          <div
            v-for="(gamelist, j) in matchlist.game_list"
            :key="j"
            @click="handleClickMatch(matchlist.league_id, gamelist.id)"
            class="match-item my-3"
            :class="[
              gamelist.id === null ? 'opacity-50' : '',
              gamelist.status === 'live' ? 'live' : '',
            ]"
          >
            <div
              v-if="gamelist.round_id !== null || gamelist.result !== 'wait'"
              class="match-number active"
            >
              <div class="match-number-active">
                คู่ที่
                {{
                  matchlist.can_new_game === true
                    ? gamelist.id
                    : gamelist.position
                }}
              </div>
              <div
                class="match-number-active"
                :class="[
                  gamelist.result === 'home' ? 'text-white bg-red' : '',
                  gamelist.result === 'away' ? 'text-white bg-blue' : '',
                ]"
              >
                <div
                  v-if="gamelist.result !== null && gamelist.result !== 'wait'"
                  class="w-100 text-center"
                >
                  <span v-if="gamelist.result === 'home'">แดงชนะ</span>
                  <span v-else-if="gamelist.result === 'away'">
                    น้ำเงินชนะ
                  </span>
                  <span v-else-if="gamelist.result === 'draw'">เสมอ</span>
                  <span v-else>ยกเลิก</span>
                </div>
                <div v-else>
                  <span class="color-round"></span>&nbsp;{{
                    gamelist.round_text
                  }}
                </div>
              </div>
            </div>
            <div v-else class="match-number">
              <div class="match-number-text">
                คู่ที่
                {{
                  matchlist.can_new_game === true
                    ? gamelist.id
                    : gamelist.position
                }}
              </div>
            </div>
            <div class="match-name">
              <div class="position-relative">
                <div class="color-box bg-red"></div>
                <div class="text-truncate">{{ gamelist.home_name }}</div>
              </div>
              <div class="position-relative">
                <div class="color-box bg-blue"></div>
                <div class="text-truncate">{{ gamelist.away_name }}</div>
              </div>
            </div>
            <div v-if="gamelist.status === 'live'" class="live-icon">
              <LiveIcon
                v-if="isMobile() === false"
                :id="'lavContainer' + i + '_' + j"
              />
              <div v-else class="badge badge-live">
                <div class="m-auto">
                  <!-- <div class="live-animated"><i class="fas fa-signal"></i></div> -->
                  <span>LIVE</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRefs } from "@vue/reactivity";
import LiveIcon from "@/components/LiveIcon.vue";
import { useMatch } from "./composable/use-match";

export default {
  name: "Match",
  components: {
    LiveIcon,
  },
  setup() {
    const { state, handleClickMatch, isMobile } = useMatch();
    return {
      ...toRefs(state),
      handleClickMatch,
      isMobile,
    };
  },
};
</script>

<style lang="scss" scoped></style>
