import { computed, onMounted, reactive } from "vue";
import { utilily } from "@/utilities/utility";
import { useStore } from "vuex";
// import { useRoute } from "vue-router";

export function useHistory() {
  const store = useStore();
  // const route = useRoute();
  const state = reactive({
    ticketLists: computed(() => store.state.priceModule.ticketLists),
    historyLists: computed(() => store.state.priceModule.historyLists),
    isLastHistory: computed(() => store.state.priceModule.isLastHistory),
    isStreamer: computed(() => store.state.defaultModule.isStreamer),
    oldHistiryId: null,
    isHistoryOpen: computed(() => store.state.priceModule.isHistoryOpen),
  });
  const { checkHistory } = utilily();
  onMounted(async () => {
    console.log(state.isLastHistory);
    const data = checkHistory(
      state.historyLists,
      state.isLastHistory,
      state.isStreamer
    );
    console.log(data);
    await store.dispatch("priceModule/getHistory", data);
  });
  const handleClickHistory = async (id) => {
    if (state.oldHistiryId === null) {
      store.commit("priceModule/SET_ISHISTORYOPEN_DATA", true);
    } else {
      if (state.oldHistiryId === id && state.isHistoryOpen) {
        store.commit("priceModule/SET_ISHISTORYOPEN_DATA", false);
      } else {
        store.commit("priceModule/SET_ISHISTORYOPEN_DATA", true);
      }
    }
    const index = state.historyLists.findIndex((element) => element.id === id);
    state.oldHistiryId = id;
    if (state.historyLists[index].result === "wait") {
      if (state.isHistoryOpen === true) {
        await store.dispatch("priceModule/getTicket", {
          id: state.oldHistiryId,
        });
      }
    } else {
      if (!state.ticketLists[id]) {
        if (state.isHistoryOpen === true) {
          await store.dispatch("priceModule/getTicket", {
            id: state.oldHistiryId,
          });
        }
      }
    }
    // if (!state.ticketLists[id]) {
    //   if (state.isHistoryOpen) {
    //     await store.dispatch("priceModule/getTicket", {
    //       id: state.oldHistiryId,
    //     });
    //   }
    // } else if (
    //   id === +route.params.id
    //   // !state.historyLists[index].result ||
    //   // state.historyLists[index].result === "wait"
    // ) {
    //   if (state.isHistoryOpen) {
    //     await store.dispatch("priceModule/getTicket", {
    //       id: state.oldHistiryId,
    //     });
    //   }
    // }
  };
  return { state, handleClickHistory };
}
