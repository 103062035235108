const defaultModule = {
  namespaced: true,
  state: {
    isSidebar: false,
    isLoggedIn: false,
    isStreamer: localStorage.getItem("streamer"),
  },
  mutations: {
    SET_IsSidebar(state, newIsSidebar) {
      state.isSidebar = newIsSidebar;
    },
    SET_IsLoggedIn(state, newIsLoggedIn) {
      state.isLoggedIn = newIsLoggedIn;
    },
    SET_IsStreamer(state, newIsStreamer) {
      state.isStreamer = newIsStreamer;
    },
  },
  actions: {},
};

export default defaultModule;
