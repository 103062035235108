import { computed, onBeforeUnmount, onMounted, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getMatch } from "@/services/match-service";
import { utilily } from "@/utilities/utility";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";

export function useMatch() {
  const router = useRouter();
  const route = useRoute();
  const store = useStore();
  const toast = useToast();
  const { getSocketURL, isMobile } = utilily();
  const state = reactive({
    matchlists: [],
    webSocket: computed(() => store.state.priceModule.webSocket),
    profile: computed(() => store.state.priceModule.profile),
    socketURL: null,
    chooseLeague: localStorage.getItem("lid"),
    isLoading: false,
    debug: {},
    fullMonths: [
      "มกราคม",
      "กุมภาพันธ์  ",
      "มีนาคม",
      "เมษายน",
      "พฤษภาคม",
      "มิถุนายน",
      "กรกฎาคม",
      "สิงหาคม",
      "กันยายน",
      "ตุลาคม",
      "พฤศจิกายน",
      "ธันวาคม",
    ],
  });
  onBeforeUnmount(() => {
    localStorage.removeItem("lid");
    if (typeof state.webSocket.off === "function") {
      state.webSocket.off();
    }
  });
  onMounted(async () => {
    console.log("league", state.chooseLeague);
    await getMatchList();
    state.socketURL = await getSocketURL();
    initWebSocket();
    localStorage.removeItem("lid");
  });
  const checkCloseTime = (closeTime) => {
    if (closeTime === null) {
      return { isClose: false, msg: "" };
    }
    console.log(closeTime.split("-")[0], closeTime.split("-")[1]);
    if (
      closeTime.split("-")[0].trim() === "00:00" &&
      closeTime.split("-")[1].trim() === "00:00"
    ) {
      return { isClose: true, msg: "Coming Soon" };
    }
    // let closeTimeString = "22.30 - 06.00";
    let closeTimeString = closeTime;
    let closeTimeStartHour = parseInt(
      closeTimeString.split("-")[0].split(":")[0]
    );
    let closeTimeStartMinute = parseInt(
      closeTimeString.split("-")[0].split(":")[1]
    );
    let closeTimeEndHour = parseInt(
      closeTimeString.split("-")[1].split(":")[0]
    );
    let closeTimeEndMinute = parseInt(
      closeTimeString.split("-")[1].split(":")[1]
    );
    var date = new Date();
    var currentTime = new Date(
      date.getTime() + (date.getTimezoneOffset() + 420) * 60 * 1000
    );
    // currentTime.setHours(3, 0, 0, 0);
    var startTime = new Date();
    startTime.setHours(closeTimeStartHour, closeTimeStartMinute, 0, 0);
    var startTime2 = new Date();
    startTime2.setHours(closeTimeStartHour, closeTimeStartMinute, 0, 0);
    var endTime = new Date();
    endTime.setHours(closeTimeEndHour, closeTimeEndMinute, 0, 0);
    var endTime2 = new Date();
    endTime2.setHours(closeTimeEndHour, closeTimeEndMinute, 0, 0);

    if (
      closeTimeEndHour < closeTimeStartHour ||
      (closeTimeEndHour === closeTimeStartHour &&
        closeTimeEndMinute <= closeTimeStartMinute)
    ) {
      startTime.setDate(startTime.getDate() - 1);
      endTime2.setDate(endTime2.getDate() + 1);
    }
    // console.log(currentTime, startTime, endTime, startTime2, endTime2);
    if (
      (currentTime >= startTime && currentTime < endTime) ||
      (currentTime >= startTime2 && currentTime < endTime2)
    ) {
      console.log("Close");
      return {
        isClose: true,
        msg:
          "เปิดเวลา " +
          closeTimeString.split("-")[1].trim() +
          "-" +
          closeTimeString.split("-")[0].trim() +
          " (GMT+7)",
      };
    } else {
      console.log("Open");
      return { isClose: false, msg: "" };
    }
  };
  const getMatchList = async () => {
    try {
      state.isLoading = true;
      var response = await getMatch(route.params.id);
      var data = response.data;
      var showState = false;
      for (let i = 0; i < data.length; i++) {
        data[i].show = false;
        const closeTime = checkCloseTime(data[i].offline_time);
        data[i].isClose = closeTime.isClose;
        data[i].isCloseMsg = closeTime.msg;
        if (data.length === 1) {
          data[i].show = true;
        } else if (state.chooseLeague !== null) {
          if (+state.chooseLeague === data[i].league_id) {
            data[i].show = true;
          }
        } else {
          for (let j = 0; j < data[i].game_list.length; j++) {
            if (data[i].game_list[j] === null) {
              data[i].game_list = [];
              return;
            }
            if (
              data[i] !== null &&
              data[i].game_list[j] !== null &&
              data[i].game_list[j].result === "wait"
            ) {
              if (showState === false) {
                data[i].show = true;
                showState = true;
                break;
              } else {
                break;
              }
            }
          }
        }
        var date = new Date(data[i].start_at * 1000);
        data[i].fullDate =
          ("0" + date.getDate()).slice(-2) +
          " " +
          state.fullMonths[date.getMonth()] +
          " " +
          (date.getFullYear() + 543);
        data[i].game_list = sortArray(data[i].game_list);
      }
      state.matchlists = data;
      console.log("response", state.matchlists);
    } finally {
      state.isLoading = false;
    }
  };
  const sortArray = (data) => {
    data.sort(function (a, b) {
      return a.position - b.position;
    });
    var temp = data;
    // var currentLiveIndex = 0;
    var currentResultIndex = 0;
    for (let index = 0; index < data.length; index++) {
      if (data[index] !== null && data[index].status === "live") {
        console.log("live");
        // temp = swapArrayElements(temp, currentLiveIndex, index);
        temp.unshift(temp.splice(index, 1)[0]);
        // currentLiveIndex++;
      }
    }
    for (let index = 0; index < data.length; index++) {
      if (
        data[currentResultIndex] !== null &&
        data[currentResultIndex].result !== "wait"
      ) {
        temp.push(temp.splice(currentResultIndex, 1)[0]);
      } else {
        currentResultIndex++;
      }
    }
    return temp;
  };
  // const swapArrayElements = (data, indexA, indexB) => {
  //   var temp = data[indexA];
  //   data[indexA] = data[indexB];
  //   data[indexB] = temp;
  //   return data;
  // };
  const handleClickMatch = async (league, id) => {
    if (id) {
      await router.push({ name: "Game", params: { id: id } });
      localStorage.setItem("lid", league);
    }
  };
  const findOneIndexArr = (id, index) => {
    for (let i = 0; i < state.matchlists.length; i++) {
      for (let j = 0; j < state.matchlists[i].game_list.length; j++) {
        if (state.matchlists[i].game_list[j][index] === id) {
          return [i, j];
        }
      }
    }
    return null;
  };
  const initWebSocket = async () => {
    console.log("initwss");
    store.commit("priceModule/SET_WEBSOCKET_DATA", {
      url: state.socketURL,
      streamerId: route.params.id,
      customer_id: state.profile.username,
    });
    // client-side
    state.webSocket.on("connect", () => {
      console.log(`connected with the id of ${state.webSocket.id}`); // x8WIv7-mJelg7on_ALbx
    });

    state.webSocket.on("msg", (data) => {
      console.log("msg", data);
    });
    // this.webSocket.on("connect_error", (data) => {
    //   console.log(data);
    // });

    state.webSocket.on("cmd", async (data) => {
      if (route.name !== "Match") {
        return;
      }
      console.log("matchlist cmd", data);
      if (data.cmd === "updateGamePrice") {
        const index = findOneIndexArr(data.msg.id, "id");
        console.log(index);
        if (index === null) {
          return;
        }
        if (data.msg.status === "live") {
          state.matchlists[index[0]].is_live = true;
        } else {
          state.matchlists[index[0]].is_live = false;
        }
        state.matchlists[index[0]].game_list[index[1]].round_id =
          data.msg.round_id;
        state.matchlists[index[0]].game_list[index[1]].round_text =
          data.msg.round_text;
        state.matchlists[index[0]].game_list[index[1]].result = data.msg.result;
        state.matchlists[index[0]].game_list[index[1]].status = data.msg.status;
        state.matchlists[index[0]].game_list = sortArray(
          state.matchlists[index[0]].game_list
        );
      } else if (data.cmd === "newGamePrice") {
        const index = state.matchlists.findIndex(
          (x) => x.league_id === data.msg.data.league_id
        );
        console.log(index);
        if (index >= 0) {
          if (state.matchlists[index].can_new_game === true) {
            state.matchlists[index].game_list = [];
          }

          state.matchlists[index].game_list.push(data.msg.data);
          state.matchlists[index].game_list = sortArray(
            state.matchlists[index].game_list
          );
          const closeTime = checkCloseTime(
            state.matchlists[index].offline_time
          );
          state.matchlists[index].isClose = closeTime.isClose;
          state.matchlists[index].isCloseMsg = closeTime.msg;
        } else {
          await getMatchList();
        }
      } else if (data.cmd === "newPosition") {
        const index = findOneIndexArr(data.msg.game_id, "game_id");
        console.log(index);
        if (index) {
          state.matchlists[index[0]].game_list[index[1]].position =
            data.msg.position;
          state.matchlists[index[0]].game_list = sortArray(
            state.matchlists[index[0]].game_list
          );
          console.log(state.matchlists);
        }
      } else if (data.cmd === "deposit ") {
        toast.success(`มีเงินฝากเข้ามา`, {
          position: "top-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        store.commit("priceModule/SET_BALANCE_DATA", data.msg.balance / 100);
      } else if (data.cmd === "withdraw ") {
        toast.success(`มีเงินถอนออกไป`, {
          position: "top-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        store.commit("priceModule/SET_BALANCE_DATA", data.msg.balance / 100);
      }
    });
    state.webSocket.on("disconnect", () => {
      console.log(`disconnected`); // undefined
    });
  };
  return {
    state,
    handleClickMatch,
    isMobile,
  };
}
