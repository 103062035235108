<template>
  <div>
    <div
      v-show="!isHiddenContent"
      @mouseenter="handleHoverVideo(true, 'mouseenter')"
      @mouseleave="handleHoverVideo(false, 'mouseleave')"
      @touchend="handleHoverVideo(true, 'touchend')"
      class="row live-cdn bg-dark"
    >
      <div v-if="gamePrice.status !== 'live'" class="livestatus-text">
        <span v-if="gamePrice.status === 'wait'"
          >ยังไม่ถึงเวลาไลฟ์สดกรุณารอสักครู่</span
        >
        <span
          v-else-if="
            gamePrice.status === 'end' && gameObj.can_new_game === false
          "
          >สิ้นสุดรายการสด ขอบคุณที่รับชม</span
        >
      </div>
      <div
        v-if="gamePrice.pin_text !== null && gamePrice.pin_text !== ''"
        class="scroll-container"
      >
        <div class="position-relative">
          <div class="scroll-text">
            {{
              gamePrice.pin_text !== null && gamePrice.pin_text !== ""
                ? gamePrice.pin_text
                : ""
            }}
          </div>
        </div>
      </div>
      <img
        v-show="isShowPoster === true && gameObj.poster !== null"
        class="poster"
        :src="poster !== null ? poster : ''"
        alt=""
      />
      <video id="videojsPlayer" class="video-js px-0" playsinline></video>
      <div
        v-if="isShowBtnPlay === true"
        @click="handleClickVideoIcon(true, true)"
        class="play-btn"
      >
        <div class="icon">
          <i class="fas fa-play-circle"></i>
        </div>
      </div>
      <div v-if="isShowChat" class="ticket-chat px-1">
        <div
          v-for="(ticket, index) in ticketArr"
          :key="index"
          class="chat"
          :id="'chat' + index"
        >
          <div v-if="ticket" class="text-start">
            <span>{{ ticket.masked_username }}</span>
            &nbsp;
            <span :class="ticket.bet_team === 'home' ? 'red' : 'blue'">{{
              ticket.bet_team === "home"
                ? checkPrice(ticket.home_price, checkPriceType(ticket), true)
                : checkPrice(ticket.away_price, checkPriceType(ticket), true)
            }}</span>
            &nbsp;
            <span>{{ `${numberWithCommas(ticket.amount / 100, 0)} บาท` }}</span>
          </div>
        </div>
      </div>
      <div v-if="voiceActorMode() === true" class="chat-btn">
        <div @click="handleClickChatIcon" class="bg">
          <div v-if="isShowChat" class="icon">
            <i class="fas fa-comment-dots"></i>
          </div>
          <div v-else class="icon"><i class="fas fa-comment-slash"></i></div>
        </div>
      </div>
      <div class="live-btn row">
        <!-- <div @click="handleClickVolumeIcon" id="VolumeIcon" class="bg me-2">
          <div v-if="!isVolumeMute" class="icon">
            <i class="fas fa-volume-up"></i>
          </div>
          <div v-else class="icon"><i class="fas fa-volume-mute"></i></div>
        </div> -->
        <div
          v-if="
            gamePrice.status === 'live' &&
            voiceActorMode() === true &&
            isShowBtnPlay === false
          "
          class="bg me-2 position-relative"
          data-bs-dismiss="modal"
          data-bs-toggle="modal"
          data-bs-target="#audioModal"
        >
          <div class="icon">
            <i class="fas fa-microphone"></i>
          </div>
        </div>
        <div
          v-else-if="
            gamePrice.status === 'live' &&
            voiceActorMode() === false &&
            isShowBtnPlay === false
          "
          class="bg me-2 position-relative"
        >
          <div
            v-if="isVolumeMute === false"
            @click="handleClickVolumeIcon(true)"
            class="icon"
          >
            <i class="fas fa-volume-up"></i>
          </div>
          <div v-else @click="handleClickVolumeIcon(false)" class="icon">
            <i class="fas fa-volume-mute"></i>
          </div>
        </div>
        <div
          v-if="
            gamePrice.http_stream_url !== null &&
            gamePrice.status === 'live' &&
            isShowBtnPlay === false
          "
          class="bg me-2"
        >
          <div
            v-if="isShowVideo === true"
            @click="handleClickVideoIcon(false)"
            class="icon"
          >
            <i class="fas fa-video"></i>
          </div>
          <div v-else class="icon" @click="handleClickVideoIcon(true)">
            <i class="fas fa-video-slash"></i>
          </div>
        </div>
        <div
          v-if="
            gamePrice.http_stream_url !== null &&
            gamePrice.status === 'live' &&
            isShowBtnPlay === false
          "
          class="bg"
        >
          <div @click="handleClickVideoFullScreen" class="icon">
            <i class="fas fa-expand"></i>
          </div>
        </div>
      </div>
      <AudioPlayer
        :showAudio="isShowAudio"
        :gameStatus="gamePrice.status"
        :leagueId="gameObj.league_id"
        :playerType="playerType"
        :showVideo="isShowVideo"
        :wss_stream_url="wss_stream_url"
        :isVolumeMute="isVolumeMute"
        @isLoading="handleAudioLoading"
        @isConnect="setHistoryHeight"
      />
    </div>
    <div
      v-if="!isHiddenContent && gameObj.mode !== 'pool'"
      class="row live-name bg-26"
    >
      <div class="col-10 match-name game">
        <div v-if="!isLoading" class="position-relative">
          <div class="color-box bg-red"></div>
          <div class="text-truncate">{{ gameObj.home_name }}</div>
        </div>
        <div v-if="!isLoading" class="position-relative">
          <div class="color-box bg-blue"></div>
          <div class="text-truncate">{{ gameObj.away_name }}</div>
        </div>
      </div>
      <div class="col align-self-center">
        <button v-if="!isLoading" class="btn btn-green">
          {{ gamePrice.round_id !== null ? gamePrice.round_text : "รอ" }}
        </button>
      </div>
    </div>
    <div
      v-else-if="!isHiddenContent && gameObj.mode === 'pool'"
      class="position-relative"
    >
      <div
        v-if="isNoNameGame() === false"
        class="row live-name pool bg-4b py-2"
      >
        <div class="col d-flex align-items-center" style="color: white">
          <span>คู่ที่ #{{ gameObj.position }}</span>
          &nbsp;
          <div
            @click="isShowName = !isShowName"
            class="cursor-pointer"
            style="color: #111111; font-size: 0.75rem"
          >
            <div v-if="isShowName === true">
              ดูเพิ่มเติม
              <i class="fas fa-chevron-up"></i>
            </div>
            <div v-else>
              ดูเพิ่มเติม&nbsp;<i class="fas fa-chevron-down"></i>
            </div>
          </div>
        </div>

        <div class="col-4 text-end">
          <span style="color: #ffe662">{{
            gamePrice.round_id !== null ? gamePrice.round_text : "รอ"
          }}</span>
        </div>
      </div>
      <div v-else class="row live-name pool bg-4b py-2">
        <div class="col-9 text-start" style="color: white">
          <span>{{
            gameObj.additional
              ? gameObj.additional.match_video_id
                ? gameObj.league_name +
                  " #" +
                  gameObj.id +
                  "/" +
                  gameObj.additional.match_video_id
                : gameObj.league_name + " #" + gameObj.id
              : gameObj.league_name + " #" + gameObj.id
          }}</span>
        </div>
        <div class="col-3 d-flex justify-content-end">
          <div class="badge bg-dark pool-badge">
            <div
              class="color-box-pool"
              :class="gamePrice.is_price_open === true ? 'bg-green' : 'bg-red'"
            ></div>
            &nbsp;
            <div>
              {{
                gamePrice.is_price_open === false
                  ? gamePrice.result !== "wait"
                    ? "Finish"
                    : gamePrice.last_change_price === null
                    ? "Not Open"
                    : "Close"
                  : "Open"
              }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="isNoNameGame() === false"
        class="match-pool-name"
        :class="isShowName === true ? 'animated' : ''"
      >
        <div class="col-10 match-name game">
          <div v-if="!isLoading" class="position-relative">
            <div class="color-box bg-red"></div>
            <div class="text-truncate">{{ gameObj.home_name }}</div>
          </div>
          <div v-if="!isLoading" class="position-relative">
            <div class="color-box bg-blue"></div>
            <div class="text-truncate">{{ gameObj.away_name }}</div>
          </div>
        </div>
      </div>
    </div>
    <PoolBet
      v-if="gameObj.mode === 'pool'"
      :gamePrice="gamePrice"
      :ticketLists="ticketLists"
      :isHiddenContent="isHiddenContent"
      :commission="commission"
      :approveTime="approveTime"
      :gameObj="gameObj"
      :isLoading="isLoading"
      :textPulse="textPulse"
      @handleClickBetRed="handleClickBetRed"
      @handleClickBetBlue="handleClickBetBlue"
      @handleClickInfo="handleClickInfo"
    />
    <NormalBet
      v-if="gameObj.mode !== 'pool'"
      :gamePrice="gamePrice"
      :isHiddenContent="isHiddenContent"
      :approveTime="approveTime"
      :gameObj="gameObj"
      :isLoading="isLoading"
      :textPulse="textPulse"
      @handleClickBetRed="handleClickBetRed"
      @handleClickBetBlue="handleClickBetBlue"
    />
    <div class="row live-tab position-relative bg-32">
      <div
        @click="handleClickTab(false)"
        class="col border-redius-top py-1"
        :class="!isHistoryTab ? 'bg-grey' : 'bg-26'"
      >
        คู่ที่ #{{
          gameObj.mode !== "pool"
            ? gameId
            : gameObj.additional
            ? gameObj.additional.match_video_id
              ? gameObj.id + "/" + gameObj.additional.match_video_id
              : gameId
            : gameId
        }}
      </div>
      <div
        @click="handleClickTab(true)"
        class="col border-redius-top py-1"
        :class="isHistoryTab ? 'bg-grey' : 'bg-26'"
      >
        ประวัติ
      </div>
      <div @click="handleClickHiddenContent" class="hidden-content-btn h-100">
        <div v-if="!isHiddenContent" class="icon">
          <i class="fas fa-angle-double-up"></i>
        </div>
        <div v-else class="icon">
          <i class="fas fa-angle-double-down"></i>
        </div>
      </div>
    </div>
    <div class="row history-list">
      <div v-if="!isHistoryTab">
        <div v-if="!ticketLists[gameId]" class="no-ticket mt-4">
          ยังไม่มีรายการ
        </div>
        <div v-else>
          <div
            v-for="(ticketList, index) in ticketLists[gameId]"
            :key="index"
            class="row live-state mx-1 my-2"
          >
            <div class="col-7 col-md-8 detail bg-1c">
              <div class="row text-start">
                <div
                  class="col-12 text-truncate py-2"
                  :class="ticketList.bet_team === 'home' ? 'red' : 'blue'"
                >
                  <span v-if="ticketList.bet_team === 'home'">{{
                    gameObj.home_name
                  }}</span>
                  <span v-else>{{ gameObj.away_name }}</span>
                </div>
                <div class="col-4 text-truncate pb-2">
                  {{ ticketList.round_text }}
                </div>

                <div class="col text-truncate pb-2">
                  <span v-if="ticketList.bet_team === 'home'">{{
                    ticketList.status !== 300 && ticketList.status !== 890
                      ? checkPrice(ticketList.home_price, ticketList.priceType)
                      : "ยกเลิก"
                  }}</span>
                  <span v-else>{{
                    ticketList.status !== 300 && ticketList.status !== 890
                      ? checkPrice(ticketList.away_price, ticketList.priceType)
                      : "ยกเลิก"
                  }}</span>
                </div>
              </div>
            </div>
            <div class="col-5 col-md-4 state text-end bg-26 ps-0">
              <div class="py-2">{{ numberWithCommas(ticketList.amount) }}</div>
              <!-- <div v-if="ticketList.status_label" class="pb-2 red">ยกเลิก</div> -->
              <div
                v-if="ticketList.status === 150"
                class="pb-2 text-truncate yellow"
              >
                รอดำเนินการ
              </div>
              <div
                v-else-if="
                  ticketList.status === 300 ||
                  ticketList.status === 670 ||
                  ticketList.status === 680 ||
                  ticketList.status === 890
                "
                class="pb-2 red"
              >
                <span
                  v-if="ticketList.status === 300 || ticketList.status === 890"
                >
                  ปฏิเสธรายการ
                </span>
                <span v-else> ยกเลิก </span>
              </div>
              <div
                v-else-if="
                  ticketList.status === 200 ||
                  ticketList.status === 600 ||
                  ticketList.status === 650 ||
                  ticketList.status === 680 ||
                  ticketList.status === 690
                "
                class="pb-2 green"
              >
                เรียบร้อยแล้ว
              </div>
              <div v-else class="pb-2 red">ไม่มีสถานะ</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <History
          @handleClickHistory="handleClickHistory"
          :gamePrice="gamePrice"
          :commission="commission"
        />
      </div>
    </div>
    <Modal bgcolor="bg-black" id="audioModal" style="border: solid 3px #2e2e2e">
      <template #content>
        <div class="d-flex container-fluid mt-4 justify-content-between">
          <div class="btn-modal-close" data-bs-dismiss="modal">
            <i class="fas fa-times"></i>
          </div>
          <button
            @click="handleClickAudioIcon('stage')"
            class="btn btn-audio"
            id="stageSound"
          >
            <!-- <div class="loading">
              <div class="spinner-border loading-audio" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div> -->
            <div v-if="audioState === 'stage'">
              <i class="fas fa-pause"></i> เสียงสนาม
            </div>
            <div v-else><i class="fas fa-play"></i> เสียงสนาม</div>
          </button>
          <button
            @click="handleClickAudioIcon('dealer')"
            class="btn btn-audio"
            id="dealerSound"
          >
            <!--  -->
            <div v-if="audioState === 'dealer'">
              <i v-if="isLoadingAudio === false" class="fas fa-pause me-1"></i>
              <div v-else class="spinner-border audio me-1" role="status"></div>
              <span>เสียงพากย์ราคา</span>
            </div>
            <div v-else><i class="fas fa-play"></i> เสียงพากย์ราคา</div>
          </button>
        </div>
      </template>
      <template #footer> </template>
    </Modal>
    <Modal bgcolor="bg-grey" id="numpadModal">
      <template #content>
        <div class="container-fluid mt-2">
          <div
            class="row align-items-center header mb-2"
            style="background-color: unset"
          >
            <div class="col text-start px-0">
              <span class="badge bg-black"
                >กระเป๋าเงิน:
                {{
                  profile.balance >= 100000
                    ? abbrNum(profile.balance, 2)
                    : numberWithCommas(profile.balance)
                }}</span
              >
            </div>
            <div class="col text-start px-0">
              <span v-if="isRed"
                >เดิมพัน: {{ gamePrice.bet_min > 0 ? gamePrice.bet_min : 1 }}-{{
                  gamePrice.home_price_limit
                }}</span
              >
              <span v-else-if="isBlue"
                >เดิมพัน: {{ gamePrice.bet_min > 0 ? gamePrice.bet_min : 1 }}-{{
                  gamePrice.away_price_limit
                }}</span
              >
            </div>
            <div
              id="btnCloseDigitpad"
              @click="handleCloseNumpad()"
              class="col-1 text-end px-0"
              data-bs-dismiss="modal"
            >
              <i class="fas fa-times"></i>
            </div>
          </div>
          <div class="row align-items-center bet-price">
            <div class="col-2 text-place">จำนวน</div>
            <div class="col text-end">
              <span class="text-price">{{ betPrice }}</span
              >&nbsp;
              <span class="text-baht">฿</span>
            </div>
          </div>
          <div class="row numpad mt-3">
            <div class="col-9 numpad-border-right ps-0">
              <div class="row">
                <div
                  v-for="(numpad, index) in numpads"
                  :key="index"
                  class="col-4"
                  :class="[
                    index > 2 ? 'pt-2' : '',
                    index === 0 || index === 3 || index === 6 || index === 9
                      ? 'pe-1'
                      : '',
                    index === 1 || index === 4 || index === 7 || index === 10
                      ? 'px-2'
                      : '',
                    index === 2 || index === 5 || index === 8 || index === 11
                      ? 'ps-1'
                      : '',
                  ]"
                >
                  <button
                    @click="handleClickNumpad(numpad)"
                    class="btn btn-numpad"
                  >
                    <span v-if="numpad === 'remove'"
                      ><i class="fas fa-undo"></i
                    ></span>
                    <span v-else-if="numpad === 'delete'"
                      ><i class="fas fa-backspace"></i
                    ></span>
                    <span v-else>{{ numpad }}</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-3 pe-0">
              <div class="row">
                <div
                  v-for="(plus, index) in pluses"
                  :key="index"
                  class="col-12"
                  :class="[index > 0 ? 'pt-2' : '']"
                >
                  <button @click="handleClickPlus(plus)" class="btn btn-plus">
                    +{{ plus }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <button
              v-if="isBet === true"
              id="btnSubmit"
              @click="handleClickSubmitPrice()"
              class="btn btn-black"
              :class="isSubmit ? 'success' : ''"
            >
              <!-- :data-bs-dismiss="isSubmit ? 'modal' : ''" -->
              <span v-if="isLoadingSubmit === false">ยืนยัน</span>
              <div v-else class="spinner-border loading-submit" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
            <button
              v-else
              class="btn btn-black"
              :class="isSubmit ? 'success' : ''"
              disabled
            >
              <span>{{ countTimeShow }}</span>
            </button>
          </div>
          <div v-if="isRed" class="row detail-bet red py-2 mt-3">
            <div class="col-12 text-name">{{ gameObj.home_name }}</div>
            <div v-if="gameObj.mode !== 'pool'" class="col-6 text-price">
              {{ checkPrice(gamePrice.home_price) }} @{{
                numberWithCommas(multipleRed + 1)
              }}
            </div>
            <div v-if="gameObj.mode !== 'pool'" class="col-6 px-0 text-price">
              ( {{ numberWithCommas(betPrice) }} ได้
              {{ numberWithCommas(betPrice * multipleRed) }} )
            </div>
            <div v-if="gameObj.mode === 'pool'" class="col-12 px-0 text-price">
              ราคาต่อรองจะสรุปหลังปิดรอบ
            </div>
          </div>
          <div v-else-if="isBlue" class="row detail-bet blue py-2 mt-3">
            <div class="col-12 text-name">{{ gameObj.away_name }}</div>
            <div v-if="gameObj.mode !== 'pool'" class="col-6 text-price">
              {{ checkPrice(gamePrice.away_price) }} @{{
                numberWithCommas(multipleBlue + 1)
              }}
            </div>
            <div v-if="gameObj.mode !== 'pool'" class="col-6 px-0 text-price">
              ( {{ numberWithCommas(betPrice, 2) }} ได้
              {{ numberWithCommas(betPrice * multipleBlue) }} )
            </div>
            <div v-if="gameObj.mode === 'pool'" class="col-12 px-0 text-price">
              ราคาต่อรองจะสรุปหลังปิดรอบ
            </div>
          </div>
        </div>
      </template>
      <template #footer> </template>
    </Modal>
  </div>
</template>

<script>
import { useGame } from "./composable/use-game";
import { utilily } from "@/utilities/utility";
import Modal from "@/components/Modal.vue";
import AudioPlayer from "@/components/game/AudioPlayer.vue";
import NormalBet from "@/components/game/NormalBet.vue";
import PoolBet from "@/components/game/PoolBet.vue";
import History from "@/views/history/History.vue";
import { toRefs } from "@vue/reactivity";

export default {
  components: {
    Modal,
    AudioPlayer,
    History,
    NormalBet,
    PoolBet,
  },
  setup() {
    const {
      state,
      handleClickNext,
      handleAudioLoading,
      handleClickChatIcon,
      handleClickVolumeIcon,
      handleClickVideoIcon,
      handleClickAudioIcon,
      handleClickHiddenContent,
      handleClickHistory,
      handleClickTab,
      handleClickBetRed,
      handleClickBetBlue,
      handleClickNumpad,
      handleClickPlus,
      handleCloseNumpad,
      handleClickSubmitPrice,
      checkPriceType,
      handleClickInfo,
      handleClickIsShowName,
      isNoNameGame,
      setHistoryHeight,
      handleClickVideoFullScreen,
      handleHoverVideo,
      voiceActorMode,
    } = useGame();

    const { numberWithCommas, abbrNum, checkPrice } = utilily();
    return {
      ...toRefs(state),
      handleClickNext,
      handleAudioLoading,
      handleClickChatIcon,
      handleClickVolumeIcon,
      handleClickAudioIcon,
      handleClickVideoIcon,
      handleClickHiddenContent,
      handleClickHistory,
      handleClickTab,
      handleClickBetRed,
      handleClickBetBlue,
      handleClickNumpad,
      handleClickPlus,
      handleCloseNumpad,
      handleClickSubmitPrice,
      checkPriceType,
      handleClickInfo,
      isNoNameGame,
      setHistoryHeight,
      handleClickVideoFullScreen,
      handleHoverVideo,
      voiceActorMode,
      handleClickIsShowName,
      checkPrice,
      numberWithCommas,
      abbrNum,
    };
  },
};
</script>

<style lang="scss" scoped>
.livestatus-text {
  z-index: 3;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  font-family: "JamjureeSemibold";
  & span {
    background: rgba(0, 0, 0, 0.75);
    padding: 0.25rem 0.5rem;
  }
}
.match-pool-name {
  z-index: 1;
  position: absolute;
  top: -20px;
  width: 105%;
  margin: 0 -0.75rem;
  background: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(2px);
  -webkit-transition: top 0.3s ease;
  &.animated {
    top: 40px;
  }
}
.pool-badge {
  display: flex;
  width: max-content;
  font-size: 13px;
  min-width: max-content;
}
</style>
